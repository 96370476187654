import { Button, Drawer, Row, Table, Typography, Col, Alert, message } from 'antd'
import React, { useEffect } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { getDetailsErrors } from '../../store/sandbox/selectors';
import styles from './Sandbox.module.css'
import { getReqDetails, getSandboxDetailsLoader, getSandboxFileErrors, getSampleErrors } from '../../store/sandbox/selectors';
import { useDispatch, useSelector } from 'react-redux';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import {
    resetReportsError,
    getCheckUrl
} from '../../store/sandbox';
import { IconErrorHash } from '../../icons/IconErrorHash';
import { changeKeyToTitle, getTableDataset, checkTableColumns, parseValue } from './utils';
import { getUrlLoader, getIpLoader, getCategoryErrors, getUrlData } from '../../store/sandbox/selectors';

const { Title, Text } = Typography


export default function DetailsDrawer({ openDetailsDrawer, setOpenDetailsDrawer, setRequestUid, requestUid }) {
    const isLoader = useSelector(getSandboxDetailsLoader);
    const sampleError = useSelector(getSampleErrors)
    const detailsErrors = useSelector(getDetailsErrors)
    let detailsData = {};
    let tableData = {};
    const dispatch = useDispatch()
    const reportsError = useSelector(getSandboxFileErrors)
    const allDetailsData = useSelector(getReqDetails)
    const urlLoader = useSelector(getUrlLoader);
    const ipLoader = useSelector(getIpLoader);
    const categoryErrors = useSelector(getCategoryErrors)
    const urlData = useSelector(getUrlData)



    useEffect(() => {
        if (allDetailsData) {
            if (allDetailsData?.file_url && allDetailsData?.file_url !== 'unknown') {
                dispatch(getCheckUrl(allDetailsData?.file_url))
            }
        }

    }, [allDetailsData])

    const [messageApi, contextHolder] = message.useMessage();
    

    if (allDetailsData) {

        const { uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name, file_sample, file_url, source_ip, ...rest } = allDetailsData;
        detailsData = {
            uid, sha256, verdict, first_seen, last_seen, hits, file_size, file_type, file_name,
            file_sample,
            file_url, source_ip
        };
        tableData = { ...rest };
    }


    const titleCloseIcon = (
        <div className={styles.detailsDrawerCloseIconWrapper}>
            <Button
                icon={<CloseOutlined />}
                onClick={() => { setOpenDetailsDrawer(false); setRequestUid(null) }}
                className={styles.detailsDrawerCloseIconButton}></Button>
        </div>)




    return (
        <Drawer
            open={openDetailsDrawer}
            width='800px'
            onClose={() => { dispatch(resetReportsError()); setOpenDetailsDrawer(false); setRequestUid(null) }}
            maskClosable
            closeIcon={false}
        >
            {contextHolder}
            {titleCloseIcon}
            {(<div className={styles.detailsDrawerWrapper} style={{ opacity: isLoader ? 0.2 : 1}}>
                {isLoader && <PreloaderNew />}
                <Row>
                    <Title className={styles.detailsDrawerTitle} level={3}>Детальная информация</Title>
                </Row>
                {detailsErrors && <Alert message={detailsErrors} type="error" showIcon />}
                {sampleError && <Alert message={sampleError} type="error" showIcon />}

                {Object.keys(detailsData).map(key => {
                    if (key !== 'uid' && key !== 'verdict_fortisandbox' && key !== 'finished_fortisandbox_at' && !(key === 'file_sample' && detailsData[key] === false)
                        && !(key === 'file_url' && (detailsData[key] === 'unknown' || detailsData[key] === null))
                        && !(key === 'source_ip' && detailsData[key] === 'unknown' || detailsData[key] === null)
                    ) {
                        return (
   
                            <Row className={styles.detailsDrawerItem} key={key}>
                                <Col span={9} className={styles.detailsDrawerDetailsTitle}>
                                    {changeKeyToTitle(key)}
                                </Col>
                                <Col span={15} className={styles.detailsDrawerDetailsValues}>
                                    {parseValue(key, detailsData[key], urlLoader, urlData, ipLoader, categoryErrors, dispatch, messageApi, requestUid, detailsData)}
                                </Col>
                            </Row>
                        )
                    }
                })}

                <Row>
                    <Title className={styles.detailsDrawerTableTitle} level={5}>Таблица проверок</Title>
                </Row>
                {reportsError && <div className={styles.detailsDrawerErrorsWrapper}>
                    <IconErrorHash /><Text className={styles.detailsDrawerErrorsText}>{reportsError}</Text>
                </div>}
                <Table
                    className={styles.table}
                    columns={checkTableColumns(detailsData, dispatch)}
                    dataSource={getTableDataset(tableData)}
                    pagination={false}
                    rowKey={(obj) => obj?.source + 'details'}
                />

            </div>)}

        </Drawer>
    )
}