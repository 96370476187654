import React, { useEffect, useState } from "react";
import { Layout as AntdLayout } from "antd";
import HeaderNew from "./HeaderNew/HeaderNew";
import SidebarNew from "./SidebarNew/SidebarNew";
import SetPasswordModal from "./SetPassword/SetPasswordModal";
import BlancPage from "../BlancPage";
import { useDispatch } from "react-redux";
import { getVerifyToken } from "../../store/auth";
import styles from './UI.module.css'

function Layout({ children, currentPageId, auth }) {
  const [modalItem, setModalItem] = useState(false);
  const dispatch = useDispatch();
  const reloadAndReset = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (!auth) {
      dispatch(getVerifyToken({ reloadAndReset }));
    }
  }, [auth]);

  return (
    <>
      {!auth && <BlancPage />}
      {auth && (
        <AntdLayout className={styles.layout}>
          <SidebarNew currentPageId={currentPageId} />
          <AntdLayout>
            <HeaderNew setModalItem={setModalItem} />
            <AntdLayout.Content className={styles.layoutContent}>
              {modalItem && (
                <SetPasswordModal
                  open={modalItem}
                  close={() => setModalItem(false)}
                />
              )}
              {children}
            </AntdLayout.Content>
          </AntdLayout>
        </AntdLayout>
      )}
    </>
  );
}

export default Layout;
