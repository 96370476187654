import React from "react";
import { Space, Typography } from "antd";
import DistributionsList from "./DistributionsList";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import styles from "./Distributions.module.css";

const { Title } = Typography;

export const DistributionsArea = ({ permissions }) => {
  return (
    <Space direction="vertical" size={10} className={styles.distributionAreaAndListSpaceTopLevel}>
      <Space className={styles.distributionAreaSpaceBottomLevel}>
        <Title level={3} style={{ margin: 0 }}>
          PARUS INTEGRITY MONITORING
        </Title>

        <Space className={styles.distributionAreaLinkArea}>
          <a
          className={styles.pageNavLink}
            href="#section1"
          >
            Cовместимость версий
          </a>
          <a
            className={styles.pageNavLink}
            href="#section2"
          >
            Документы и утилиты
          </a>
        </Space>
      </Space>
      {(permissions?.full_access || permissions?.read_access) && (
        <DistributionsList permissions={permissions} />
      )}
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </Space>
  );
};
