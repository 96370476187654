import React from "react";
import PreloaderNew from "./UI/PreloaderNew/PreloaderNew";
import styles from './BlancPage.module.css'

function BlancPage () {

    return (
<div className={styles.pageWrapper}>

    <h2>LOADING</h2>
    <PreloaderNew/>
</div>
    )
}
export default BlancPage;