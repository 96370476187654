import React from 'react'
import { useState, useEffect, useRef } from "react";
import styles from "./Distributions.module.css";
import { Table, Typography } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { useDispatch, useSelector } from "react-redux";
import { getDistributionsCompatibilityList } from "../../store/distributions";
import {
  getDistributionsCompatibilityData,
  getCompatibilityLoaders,
  getCompatibilityNextPage,
} from "../../store/distributions/selectors";
const { Title } = Typography;

export const DistributionsCompatibilityTable = ({ createColumn }) => {
  const dispatch = useDispatch();
  const isLoadingCompatibility = useSelector(getCompatibilityLoaders);
  const compatibilityData = useSelector(getDistributionsCompatibilityData);
  const lastItemCompatibility = useRef();
  const observerCompatibility = useRef();
  const [needToLoad, setNeedToLoad] = useState(false);
  const nextPage = useSelector(getCompatibilityNextPage);

  const columnsCompatibility = [
    createColumn("Сервер", "server_version", undefined, false, 100),
    createColumn(
      "Агент",
      "client_compatible_since",
      (client_compatible_since) => (
        <Typography>{client_compatible_since}</Typography>
      ),
      false,
      100
    ),
    createColumn(
      "Нода",
      "node_compatible_since",
      (node_compatible_since) => (
        <Typography>{node_compatible_since}</Typography>
      ),
      false,
      100
    ),
    createColumn(
      "Тpебования к ОС",
      "description",
      (description) => {
        const items = description?.split(",");
        return items.map((str, index) => {
          return <p key={str + index + 'compa'}className={styles.zeroMargin}>{str[str?.length-1]==='*'? str.slice(0, str?.length -1): str}</p>;
        });
      },
      false,
      100
    ),
  ];

  const fetchData = (pagination = false) => {
    dispatch(
      getDistributionsCompatibilityList({
        page: pagination ? nextPage : 1,
        pagination: pagination,
      })
    );
  };

  useEffect(() => {
    if (isLoadingCompatibility) return;
    if (observerCompatibility?.current)
      observerCompatibility?.current.disconnect();
    let callback = function (
      entries
    ) {
      if (
        entries[0].isIntersecting &&
        nextPage &&
        compatibilityData?.length > 0
      ) {
        setNeedToLoad(true);
      }
    };

    observerCompatibility.current = new IntersectionObserver(callback);
    observerCompatibility.current.observe(lastItemCompatibility?.current);
  }, [isLoadingCompatibility]);

  useEffect(() => {
    (async () => {
      if (needToLoad && nextPage) {
        fetchData(true);
        setNeedToLoad(false);
      }
    })();
  }, [needToLoad]);

  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, []);

  return (
    <>
      <div className={styles.descriptions}>
        <Title level={3} id="section1">
          Cовместимость версий
        </Title>
        <div className={styles.distributionsCompatibilityTableAndDocTableArea}>
          <Table
            loading={{
              indicator: <PreloaderNew />,
              spinning: isLoadingCompatibility,
            }}
            columns={columnsCompatibility}
            rowKey={(obj) => obj.id}
            size="small"
            pagination={false}
            className={`${styles.table} ${styles.table_header}`}
            dataSource={compatibilityData}
          />
          <div ref={lastItemCompatibility} className={styles.lastEl} />
        </div>
      </div>
    </>
  );
};
