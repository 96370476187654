import React from "react";
import { Space, Table, Typography } from "antd";
import styles from './ThreatVaultModal.module.css'

const { Text } = Typography;

function ThreatVaultCardCveNew({ data, setModalItem }) {

  const columns = [
    createColumn(
      "Signature",
      "name",
      (_, row) => (
        <Space size={2} direction="vertical" onClick={() => setModalItem(row)}>
          <Text underline>{row.name || "Нет данных"}</Text>
          <Text>{"Unique Threat ID: " + row.id}</Text>
        </Space>
      ),
      { cursor: "pointer" },
      (row) => setModalItem(row)
    ),
    createColumn("Severity", "severity"),
    createColumn("CVE", "cve", (_, { cve }) =>
      cve ? (
        <Space size={2} direction="vertical">
          {cve?.map((item, i) => (
            <Text key={item + i}>{item}</Text>
          ))}
        </Space>
      ) : (
        <Text>Нет данных</Text>
      )
    ),
    createColumn("First Release", "ori_release_version", (_, { ori_release_version, ori_release_time }) =>
      RenderColumnVersionTime(ori_release_version, ori_release_time)
    ),
    createColumn("Last Update", "latest_release_version", (_, { latest_release_version, latest_release_time }) =>
      RenderColumnVersionTime(latest_release_version, latest_release_time)
    ),
  ];
  return (
    <Table
      columns={columns}
      size="small"
      pagination={{ position: ["bottomCenter"] }}
      className={styles.threatVaultCardCveTable}
      dataSource={data}
    />
  );
}



function RenderColumnVersionTime(version, time) {
  return version ? (
    <Text>
      {version} ({time})
    </Text>
  ) : (
    <Text>Нет данных</Text>
  );
}

function createColumn(title, key, render, style, onClick) {
  return {
    title,
    key,
    dataIndex: key,
    render,
    onCell: (record, rowIndex) => ({
      onClick: (e) => onClick?.(record, rowIndex, e),
      style,
    }),
  };
}

export default ThreatVaultCardCveNew;
