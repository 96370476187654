import React from "react";
import { Button, Drawer, Space, Typography } from "antd";
import { IconClose } from "../../icons/IconClose";
import { useSelector } from "react-redux";
import {
 // getTVaultIdData,
  getTvaultLoader,
} from "../../store/threatvault/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import ThreatVaultCardNew from "./ThreatVaultCardNew";
import styles from './ThreatVaultModal.module.css'
const { Title } = Typography;
function ThreatVaultModal({ open, close, data }) {
  //const data = useSelector(getTVaultIdData);
  const isLoader = useSelector(getTvaultLoader);

  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      styles={{ header: { display: "none" } }}
    >
      <Space
        className={styles.threatVaultModalTitle}
      >
        <Button
          onClick={close}
          icon={<IconClose className={styles.closeModalIcon} />}
          type="ghost"
          className={styles.closeModalButton}
        />
      </Space>
      <Space
        direction="vertical"
        size={24}
        className={styles.threatVaultModalWrapper}
      >
        <Title className={styles.threatVaultModalTitle} level={2}>
          Детальная информация
        </Title>
        {isLoader && <PreloaderNew />}
        {data && <ThreatVaultCardNew data={data} />}
        {/* <Descriptions bordered size="small" column={1}
					className={styles.descriptions}
					labelStyle={{ backgroundColor: "transparent", width: 180, verticalAlign: "top" }}
				>
					<DescItem label="Name">{data?.result[0].name}</DescItem>
					<DescItem label="Unique Threat ID">{data?.result[0].id}</DescItem>
					<DescItem label="Description">{data?.result[0].description}</DescItem>
					<DescItem label="Category">{data?.result[0].category}</DescItem>
					<DescItem label="Minimum Version">{data?.result[0].min_version}</DescItem>
					<DescItem label="Maximum Version">{data?.result[0].max_version}</DescItem>
					<DescItem label="Severity">{data?.result[0].severity}</DescItem>
					<DescItem label="CVE">{render__list(data?.result[0].cve)}</DescItem>
					<DescItem label="Related sha256 hashes">{render__list(data?.result[0].sha256)}</DescItem>
					<DescItem label="Subtype">{data?.result[0].subtype}</DescItem>
					<DescItem label="Vendor ID">{render__list(data?.result[0].vendor)}</DescItem>
					<DescItem label="First Release">{data?.result[0].ori_release_version} {data?.result[0].ori_release_time}</DescItem>
					<DescItem label="Last Update">{data?.result[0].latest_release_version} {data?.result[0].latest_release_time}</DescItem>
					<DescItem label="Reference">{render__list(data?.result[0].reference)}</DescItem>
					<DescItem label="Status">{data?.result[0].status}</DescItem>
				</Descriptions> */}
      </Space>
    </Drawer>
  );
}

export default ThreatVaultModal;
