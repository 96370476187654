import React from "react";
import { Button, Typography } from "antd";
import { useDispatch } from "react-redux";
import { logout } from "../../../store/auth";
import { IconSuccess } from "../../../icons/IconSuccess";
import styles from './SetPasswordModal.module.css'

const { Text } = Typography;

function SetPasswordSuccess() {
  const dispatch = useDispatch();

  return (
    <>
      <div
        className={styles.setPasswordSuccessModalWrapper}
      >
        <IconSuccess />
        <Text
          className={styles.setPasswordSuccessModalTitle}
        >
          Пароль успешно изменен!
        </Text>
        <Text
          className={styles.setPasswordSuccessModalSubTitle}
        >
          Мы направим вас на страницу входа
        </Text>
        <Button
          type="primary"
          className={styles.setPasswordSuccessModalButton}
          onClick={() => dispatch(logout())}
        >
          Войти с новым паролем
        </Button>
      </div>
    </>
  );
}

export default SetPasswordSuccess;
