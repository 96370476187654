import React, { useEffect, useState } from 'react'
import { Row, Col, Button, Typography, Progress, message } from 'antd';
import { CloseOutlined, FileOutlined, CheckOutlined } from '@ant-design/icons';
import { IconCopy } from '../../icons';
import { IconErrorHash } from '../../icons/IconErrorHash';
import styles from './Sandbox.module.css'
import { copyBufferTextTable } from './utils';

const { Text } = Typography;
export default function FileCheckerResult({ isLoading, pinnedFileHash, errors, progress,
    fileSize, file, allowSendFileToCheck, handleTabChange }) {
    const oneMinuteRegex = /^[1-9]1$|^1$/;
    const twoThreeFourMinuteRegex = /^(?:[0-9]+[234]|[234])$/;
    const strokeColor = progress === 100 ? '#327FEF' : '#327FEF';
    const [fileUploadedAgo, setFileUploadedAgo] = useState(0);
    const startTimer = () => {
        const startTimestamp = new Date().getTime();
        const intervalRefresh = setInterval(() => {
            const now = new Date().getTime();
            const elapsedMilliseconds = now - startTimestamp;
            setFileUploadedAgo(Math.floor(elapsedMilliseconds / (1000 * 60)));
        }, 1000 * 60)
        return () => {
            clearInterval(intervalRefresh)
        }
    }

    useEffect(() => {
        setFileUploadedAgo(0)
        let timer = startTimer();
        return () => { timer() }
    }, [pinnedFileHash])


    const format = (percent) => {
        return `${percent}%`;
    };
    const [messageApi, contextHolder] = message.useMessage()

    return (

        <Row span={24}>
            {contextHolder}
            <Col span={20}>
                {!allowSendFileToCheck && !file && <div className={styles.fileCheckerResultErrorWrapper}>
                    <IconErrorHash />
                    <Text className={styles.fileCheckerResultErrorText}>
                        Допустимый размер файла 50МБ
                    </Text>
                </div>}
                {file && allowSendFileToCheck &&
                    <div
                        className={styles.fileCheckerResultWrapper}
                        style={{
                            border: isLoading ? '1px solid white' : errors ? '1px solid #EB575780' : '1px solid #21965380'
                        }}>
                        <div className={styles.fileCheckerResultContentWrapper}>
                            {!isLoading && pinnedFileHash &&
                                <>
                                    <FileOutlined className={styles.fileCheckerResultFileIcon + ' ' + styles.successUpload} />
                                    <div className={styles.fileCheckerResultTextWrapper}>
                                        <Text className={styles.fileCheckerResultTextFilename}>{file?.name}</Text>
                                        <div className={styles.fileCheckerResultFileHashOrSizeWrapper}>
                                            <Text className={styles.fileCheckerResultFileHashOrSizeText}>{pinnedFileHash}</Text>
                                            <Button
                                                size="small"
                                                type="text"
                                                icon={<IconCopy className={styles.copyIcon} />}
                                                onClick={() => {
                                                    copyBufferTextTable(pinnedFileHash, messageApi)
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>}

                            {isLoading &&
                                <>
                                    <FileOutlined className={styles.fileCheckerResultFileIcon + ' ' + styles.loading} />
                                    <div className={styles.fileCheckerResultTextWrapper}>
                                        <div>
                                            <Text className={styles.fileCheckerResultTextFilename}>{file?.name} </Text>
                                        </div>

                                        <div className={styles.fileCheckerResultFileHashOrSizeWrapper}>
                                            <Text className={styles.fileCheckerResultFileHashOrSizeText}>{fileSize}</Text>
                                        </div>
                                    </div>
                                </>}

                            {!isLoading && !pinnedFileHash && errors &&
                                <>
                                    <FileOutlined className={styles.fileCheckerResultFileIcon + ' ' + styles.errorUpload} />
                                    <div className={styles.fileCheckerErrorResultTextWrapper}>
                                        <Text className={styles.fileCheckerResultTextFilename + ' ' + styles.errorFilename}>{file?.name} </Text>
                                        <div className={styles.fileCheckerResultFileHashOrSizeWrapper + ' ' + styles.errorHash}>
                                            <CloseOutlined className={styles.fileCheckerResultErrorIconAndText} />
                                            <Text className={styles.fileCheckerResultErrorIconAndText}>{errors}</Text>
                                        </div>
                                    </div>
                                </>}
                        </div>
                        {!isLoading && pinnedFileHash &&
                            <div className={styles.fileCheckerResultSuccessAlertWrapper}>
                                <div className={styles.fileCheckerResultSuccessAlert}>
                                    <CheckOutlined className={styles.fileCheckerResultSuccessAlertIcon} />
                                    <Text className={styles.fileCheckerResultSuccessAlertText}>Файл загружен</Text>
                                </div>
                                <div>
                                    <Text className={styles.fileCheckerResultFileUploadedAgoText}>{fileSize}</Text><Text className={styles.fileCheckerResultFileUploadedAgoText + ' ' + styles.dash}>-</Text>
                                    <Text className={styles.fileCheckerResultFileUploadedAgoText}>{fileUploadedAgo === 0 ? 'Менее минуты назад' : fileUploadedAgo} {
                                        twoThreeFourMinuteRegex.test(fileUploadedAgo) && fileUploadedAgo !== 12 && fileUploadedAgo !== 13 && fileUploadedAgo !== 14
                                            ? 'минуты назад' :
                                            oneMinuteRegex.test(fileUploadedAgo) && fileUploadedAgo !== 11 ? 'минуту назад' : fileUploadedAgo === 0 ? '' : 'минут назад'}</Text>
                                </div>

                            </div>}


                    </div>}


                {isLoading && !pinnedFileHash && <Col span={20}> <Progress
                    className={styles.customProgress}
                    percent={progress}
                    strokeWidth={2}
                    strokeColor={strokeColor}
                    percentPosition={{
                        align: 'end',
                        type: 'outer'
                    }}
                    format={format}
                />
                </Col>}

                {pinnedFileHash && !isLoading && <div className={styles.noticeWrapper}>
                    <Text className={styles.noticeText}>
                        Файл успешно загружен, в <span className={styles.noticeLink} onClick={() => { handleTabChange(2) }}>таблице проверок</span> появилась строка файла.
                        Проверка файла может занимать до 30 минут. Пожалуйста, подождите.
                    </Text>
                </div>}



            </Col>

        </Row>)


}