import React from "react";
import { Alert, Button, Drawer, Space, Typography } from "antd";
import { IconClose, IconWarning } from "../../icons";
import styles from "./EdlStyles.module.css";

function ModalWarnings({ open, close, data, title }) {
  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      styles={{ header: { display: "none" } }}
    >
      <Space
        className={styles.modalWarningsWrapper}
      >
        <Button
          onClick={close}
          icon={<IconClose className={styles.addEdlListNewModalCloseIcon}/>}
          type="ghost"
          className={styles.closeWarningsButton}
        />
      </Space>
      <Space
        direction="vertical"
        size={12}
        className={styles.modalWarningsTitleWrapper}
      >
        <Typography.Title level={3}>
          {!title ? "Warnings" : title}
        </Typography.Title>
        {data?.length > 0 &&
          data?.map((item) => (
            <Alert
              message={item.message}
              type="warning"
              key={item.message + "error"}
              showIcon
              icon={<IconWarning />}
            >
              {item.message}
            </Alert>
          ))}
      </Space>
    </Drawer>
  );
}

export default ModalWarnings;
