import React, { useEffect, useState } from 'react'
import { Row, Col, Input, Button, Select, Space, Radio, Divider, List, DatePicker, Popover, ConfigProvider, Form } from 'antd';
import { CalendarOutlined, CloseOutlined } from '@ant-design/icons';
import { IconSearch } from '../../icons';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import styles from './Sandbox.module.css'


dayjs.extend(utc);
dayjs.extend(timezone);
export default function TableFiltersArea({
    setSearch,
    search,
    assetsData,
    //refreshing,
    fetchData,
    setRefreshing,
    myAssetsDrop,
    verdictFilter,
    setVerdictFilter,
    setCurrentPage,
    fileTypeFilter,
    setFileTypeFilter,
    clearFilters,
    statusFilter,
    setStatusFilter,
    point,
    setPoint,
    value,
    setValue,
    setSourceIn,
    sourceIn,
    org,
    dateAfterFilter,
    setDateAfterFilter,
    setDateBeforeFilter,
    dateBeforeFilter,
    resetInput,
    setResetInput,
    isLoader
}) {
    // const [selectedOption, setSelectedOption] = useState(null);
    // const [startDate, setStartDate] = useState(null);
    // const [endDate, setEndDate] = useState(null);
    const [form] = Form.useForm();
    const searching = Form.useWatch('searchText', form);
    const [viewDataFilter, setViewDataFilter] = useState('Выберите интервал');
    const [openDate, setOpenDate] = useState(false);

    const handleOpenChangeDate = () => setOpenDate(!openDate);
    const frequentlyUsedFilters = [];
    const dateFormat = "YYYY-MM-DD hh:mm:ss";

    useEffect(() => {
        if (dateBeforeFilter === null && dateAfterFilter === null) {
            setViewDataFilter('Выберите интервал')
        }
    }, [dateBeforeFilter, dateAfterFilter])
    useEffect(() => {
        if (!form) return;
        form.resetFields()
        setSearch(state => ({ ...state, value: '' }))

    }, [form, resetInput]);

    const resetDateFilter = (value) => {
        const date =
            value.replaceAll('T', ' ')
        return dayjs(date, dateFormat);
    };


    const changeDateToUTCFormatString = (date) => {
        return date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[0].split('.').reverse().join('-') + 'T' + date?.$d.toLocaleString("ru-RU").replace(",", "").split(' ')[1]
    }

    const handleChangeDateAfter = (e) => {
        if (e?.$d) {
            setDateAfterFilter(changeDateToUTCFormatString(e))
            if (dateBeforeFilter) {
                setViewDataFilter(
                    e?.$d.toLocaleString("ru-RU").replace(",", "") +
                    " - " +
                    dateBeforeFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + dateBeforeFilter.toLocaleString('ru-RU').split('T')[1]
                );
            }
            else
                setViewDataFilter(
                    e?.$d.toLocaleString("ru-RU").replace(",", "") + " - "
                );
        } else setDateAfterFilter(null);
    };
    const handleChangeDateBefore = (e) => {
        if (e?.$d) {
            setDateBeforeFilter(changeDateToUTCFormatString(e))
            if (dateAfterFilter) {
                setViewDataFilter(
                    dateAfterFilter.toLocaleString('ru-RU').split('T')[0].split('-').reverse().join('.') + ' ' + dateAfterFilter.toLocaleString('ru-RU').split('T')[1] +
                    " - " +
                    e?.$d.toLocaleString("ru-RU").replace(",", "")
                );
            } else
                setViewDataFilter(
                    " - " + e?.$d.toLocaleString("ru-RU").replace(",", "")
                );
        } else setDateBeforeFilter(null);
    };
    const calculateMonth = () => {
        let date = new Date();
        date.setDate(date.getDate() - 30);
        return date;
    };
    const content = (
        <List
            size="small"
            header={false}
            footer={
                <div>
                    <hr />
                    <Space direction="vertical">
                        <span>Начало (UTC)</span>
                        <DatePicker
                            renderExtraFooter={() => null}
                            className={styles.sandboxTableFiltersAreaDatePicker}
                            footer={null}
                            format="DD-MM-YYYY hh:mm:ss"
                            onChange={(e) => {
                                setCurrentPage(1);
                                handleChangeDateAfter(e);
                            }}
                            showNow={false}
                            showTime
                            showToday={false}
                            placeholder="Выберите дату начала"
                            disabledDate={(d) => {
                                !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                            }}
                            value={
                                dateAfterFilter ? resetDateFilter(dateAfterFilter) : null
                            }
                        />
                        <span>Окончание (UTC)</span>
                        <DatePicker
                            showNow={false}
                            className={styles.sandboxTableFiltersAreaDatePicker}
                            showToday={false}
                            renderExtraFooter={() => null}
                            showTime
                            format="DD-MM-YYYY HH:mm:ss"
                            disabledDate={(d) => {
                                !d || d.isBefore(calculateMonth()) || d.isAfter(new Date())
                            }}
                            onChange={(e) => {
                                setCurrentPage(1);
                                handleChangeDateBefore(e);
                            }}
                            placeholder="Выберите дату окончания"
                            value={
                                dateBeforeFilter
                                    ? resetDateFilter(dateBeforeFilter)
                                    : null
                            }
                        />
                    </Space>
                </div>
            }
            dataSource={frequentlyUsedFilters}
            renderItem={(item) => (
                <List.Item
                    className={styles.sandboxTableFiltersAreaDatePickerListItem}
                >
                    {item}
                </List.Item>
            )}
        />
    );


    const selectDropdown = (menu, setPoint, point, setValue) => {
        return (
            <>
                <Radio.Group
                    onChange={(e) => {
                        if (e.target.value === 'api') {
                            setPoint(e.target.value)
                            setSourceIn('01')
                            setValue('API')
                        }
                        else {
                            setPoint(e.target.value);
                            e.target.value === 'customer' ? setSourceIn('02') : setSourceIn('00,03')
                            setValue(null)
                        }

                    }}
                    value={point}
                >
                    <div
                        className={styles.statisticFiltersRadioGroup}
                    >
                        <Radio className={styles.statisticFiltersRadioGroupText} value={'firewall'}>Устройство</Radio>
                        <Radio className={styles.statisticFiltersRadioGroupText} value={'customer'}>Пользователь</Radio>
                        <Radio className={styles.statisticFiltersRadioGroupText} value={'api'}>API</Radio>

                    </div>
                </Radio.Group>
                {point !== 'api' && <Divider className={styles.statisticFiltersRadioGroupDivider} />}
                {(point === 'customer' || point === 'firewall') && menu}
            </>
        );
    };
    const getSelectArray = (key) => {
        if (key === 'firewall') { return myAssetsDrop }
        if (key === 'customer') { return org.users }
    }








    const frequency = {

        lastHour: {
            numberDate: new Date() - 3600000,
            title: "За последний час",
        },
        last24Hours: {
            numberDate: new Date() - 3600000 * 24,
            title: "За последние 24 часа",
        },
        last7days: {
            numberDate: new Date() - 3600000 * 24 * 7,
            title: "За последние 7 дней",
        },
        thisMonth: {
            numberDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            title: "За текущий месяц",
        },
        last3Months: {
            numberDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
            title: "За последние 3 месяца",
        },
        lastYear: {
            numberDate: new Date(new Date().setYear(new Date().getFullYear() - 1)),
            title: "За год",
        },

    };






    const handleSetDateFilters = (value) => {
        if (frequency[value]) {
            setDateBeforeFilter(null)
            setViewDataFilter(frequency[value].title);
            const actualDate = new Date(frequency[value].numberDate);
            setDateAfterFilter(actualDate.toISOString());
        }

        setOpenDate(false);
    };

    Object.keys(frequency).forEach((key, i) => {
        frequentlyUsedFilters.push(
            <Button
                className={styles.frequencyFilterButton}
                key={i}
                onClick={() => {
                    handleSetDateFilters(key);
                }}
            >
                {frequency[key].title}
            </Button>
        );
    });
    const selectBefore = (
        <Select defaultValue="sha256" style={{ height: '44px', borderRadius: '4px', width: 130 }} onChange={e => { setResetInput(!resetInput); setSearch({ value: '', type_search: e }) }} value={search?.type_search}>
            <Select.Option value="sha256">SHA256</Select.Option>
            <Select.Option value="search">ИМЯ ФАЙЛА</Select.Option>
        </Select>
    );
    return (
        <>
            <ConfigProvider
                theme={{
                    components: {
                        Alert: {
                            defaultPadding: "12px 16px",
                            colorText: "#4B4F55",
                        },
                        Button: {
                            controlPaddingHorizontal: 16,
                            controlHeight: 44,
                            colorText: "#327FEF",
                        },
                        Select: {
                            controlHeight: 44,
                        },
                        Input: {
                            controlHeight: 44,
                            paddingInline: 16,
                            borderRadius: '4px 0 0 4px'
                        },
                    },
                }}
            >
                <Row gutter={16} style={{ marginBottom: '16px' }}>

                    <Col span={12}>
                        <Form onFinish={({ searchText }) => setSearch(state => ({ ...state, value: searchText }))} style={{ display: 'flex', gap: 0, width: '100%' }} form={form}>
                            <Form.Item name="searchText" style={{ width: '100%' }}
                                rules={[
                                    { required: true, message: "Введите значение" },
                                    {
                                        /* new RegExp('^[a-zA-Z]:\\(((?![<>:"\\|?*]).)+((?<![ .])\\)?)*$'), */
                                        pattern: search?.type_search === 'sha256' ? /^[a-f0-9]{64}$/i : '',
                                        message: "Вы вводите невалидное значение sha256",
                                    },
                                ]}
                            >
                                <Input addonBefore={selectBefore}
                                    prefix={<IconSearch style={{ color: "#C1C2C9" }} />}
                                    //suffix={search?.value && <CloseOutlined style={{color:'red', cursor:'pointer'}} onClick={()=>setResetInput(!resetInput)} />}
                                    style={{ height: '44px', }}
                                    allowClear={{ clearIcon: <CloseOutlined style={{ color: 'red', cursor: 'pointer' }} onClick={() => setResetInput(!resetInput)} /> }}
                                    disabled={!isLoader ? false : true}
                                    placeholder={search?.type_search === 'sha256' ? "Поиск по sha256" : 'Поиск по имени файла'}

                                /></Form.Item>
                            <Button htmlType="submit" style={{ height: '44px', backgroundColor: '#2E496F', color: 'white', border: '1px solid #2E496F', borderRadius: '0 4px 4px 0' }}

                            >Поиск</Button></Form>
                    </Col>
                    <Col span={10}>

                        <Space.Compact
                            title="Выберите интервал"
                            className={styles.chooseIntervalWrapper}
                        >
                            <Popover
                                placement="bottomLeft"
                                open={openDate}
                                onOpenChange={handleOpenChangeDate}
                                title={false}
                                content={content}
                                trigger="click"
                                disabled={(assetsData || !isLoader) ? false : true}
                            >
                                <Button
                                    className={styles.chooseWrapperButton}
                                    style={{
                                        background: isLoader ? 'rgba(0, 0, 0, 0.04)' : '',
                                        color: viewDataFilter !== 'Выберите интервал' ? 'black' : '#C1C2C9'
                                    }}
                                >
                                    <CalendarOutlined
                                        className={styles.calendarIcon}
                                    />
                                    <span className={styles.showDataFilter}>
                                        {viewDataFilter}
                                    </span>
                                </Button>
                            </Popover>
                        </Space.Compact>
                    </Col>
                    <Col span={2}>

                        <Button
                            className={styles.filtersButton + ' ' + styles.renew}
                            onClick={() => {
                                setRefreshing(true)
                                fetchData()
                            }}
                        >
                            Обновить
                        </Button>

                    </Col>
                </Row>

                <Row gutter={16} className={styles.sandboxFiltersAreaSecondRow}>
                    <Col span={6}>
                        <Select
                            disabled={!isLoader ? false : true}
                            showSearch
                            maxTagCount={1}
                            mode={(point === 'customer' || point === 'firewall') ? "multiple" : 'single'}
                            allowClear={true}
                            placeholder="Отправитель"
                            className={styles.statisticsFiltersSelector}
                            optionFilterProp={point === 'customer' ? 'label' : 'value'}
                            dropdownRender={(menu) =>
                                selectDropdown(menu, setPoint, point, setValue)
                            }
                            value={value || undefined}
                            onChange={(e) => { if (e) { setCurrentPage(1); setValue(e); } else { setCurrentPage(1); setValue(null); setPoint(null) } }}
                            // onDeselect{()=> }
                            onClear={() => { setPoint(null), setValue(null), setCurrentPage(1); setSourceIn('') }}
                            options={
                                getSelectArray(point)?.map((a) => ({
                                    value: a.id ? a.id : a.firewall_id,
                                    label: a.firewall_id ? a.firewall_id : a.first_name + ' ' + a.last_name,
                                }))
                            }
                        />

                    </Col>
                    <Col span={6}>
                        <Select
                            maxTagCount={2}
                            data-testid='status-selector'
                            className={styles.sandboxFiltersStatusSelector}
                            onChange={(e) => {
                                setCurrentPage(1);
                                setStatusFilter(e)
                            }}
                            allowClear={true}
                            mode='multiple'
                            placeholder="Статус"
                            disabled={!isLoader ? false : true}
                            optionFilterProp='label'
                            value={statusFilter || undefined}
                            options={[
                                { value: "accepted,pending,stage_01,stage_02,stage_03", label: "ПРОВЕРЯЕТСЯ" },
                                { value: "finished,succeeded", label: "ПРОВЕРЕН" },
                                { value: "error,timeout,failed", label: "ОШИБКА" },
                            ]}
                        />
                    </Col>
                    <Col span={5}>
                        <Select
                            data-testid='verdict-selector'
                            maxTagCount={2}
                            className={styles.sandboxFiltersVerdictSelector}
                            mode="multiple"
                            value={verdictFilter || undefined}
                            allowClear={true}
                            disabled={!isLoader ? false : true}
                            onChange={(e) => {
                                setCurrentPage(1);
                                setVerdictFilter(e);
                            }}
                            placeholder="Вердикт"
                            options={[
                                { value: "Clean", label: "Benign" },
                                { value: "Malware", label: "Malware" },
                                { value: "Grayware", label: "Grayware" },
                                { value: 'Unknown', label: 'Unknown' }
                            ]}
                        />
                    </Col>
                    <Col span={5}>
                        <Select
                            data-testid='type-selector'
                            className={styles.sandboxFiltersVerdictSelector}
                            mode="multiple"
                            disabled={!isLoader ? false : true}
                            onChange={(e) => {
                                setCurrentPage(1)
                                setFileTypeFilter(e);
                            }}
                            placeholder="Тип файла"
                            allowClear={true}
                            maxTagCount={2}
                            value={fileTypeFilter || undefined}
                            options={[
                                { value: "pdf", label: "pdf" },
                                { value: "ms-office", label: "ms-office" },
                                { value: "pe", label: "pe" },
                                { value: "jar", label: "jar" },
                                { value: "flash", label: "flash" },
                                { value: "archive", label: "archive" },
                                { value: "script", label: "script" },
                                { value: "apk", label: "apk" },
                                { value: "linux", label: "linux" },
                            ]}
                        />
                    </Col>
                    <Col span={2}>

                        <Button
                            className={styles.filtersButton}
                            style={{
                                backgroundColor: (verdictFilter || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter || sourceIn || searching) ? '#2E496F' : "transparent",
                                color: (verdictFilter || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter || sourceIn || searching) ? 'white' : '#2E496F',
                            }}
                            disabled={!(verdictFilter || searching || fileTypeFilter || statusFilter || point || dateAfterFilter || dateBeforeFilter || sourceIn)}
                            onClick={() => clearFilters()}
                        >
                            Сбросить
                        </Button>

                    </Col>
                </Row>
            </ConfigProvider>
        </>
    )
}