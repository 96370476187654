import React from "react";
import styles from "./EdlStyles.module.css";
import { Card,  Typography,  } from 'antd';
import Dot from "../UI/Dot";
import {  useSelector } from "react-redux";
import { getEdlDataUser} from "../../store/edl/selectors";
import { numberWithSpaces } from "../../utils/numberWithSpaces";


const { Title, Text, } = Typography;
const styleColorGray = { color: "#989AA5" };

function EdlLimitsNew() {
	const dataUser = useSelector(getEdlDataUser);
	return (
		<>
			<Card>
				<Title level={4}>Текущие лимиты</Title>
				<div className={styles.limitsCards}>
					<div>
						<Text>Всего</Text>
						<div>
							<Text style={styleColorGray}>Списков </Text>
							<Text>{numberWithSpaces(dataUser?.list_stats?.total_lists)}</Text>
							<Text>/{numberWithSpaces(dataUser?.subscription?.lists_max_limit)}</Text>
						</div>
						<div>
							<Text style={styleColorGray}>Записей </Text>
							<Text>{numberWithSpaces(dataUser?.list_stats?.total_entries)}</Text>
						</div>
					</div>
					<div>
						<Text>Списки</Text>
						<div>
							<Dot color="#219653" size="6px" />
							<Text>Активные: {numberWithSpaces(dataUser?.list_stats?.active_lists)}</Text>
							<Text style={styleColorGray}>/{numberWithSpaces(dataUser?.subscription?.active_lists_max_limit)}</Text>
						</div>
						<div>
							<Dot color="#EB5757" size="6px" />
							<Text>Неактивные: {numberWithSpaces(dataUser?.list_stats?.inactive_lists)}</Text>

						</div>
					</div>
					<div>
						<Text>Domain записи</Text>
						<div>
						{/* <Text style={{fontSize: '12px'}}>Осталось: </Text> */}
							<Text>{numberWithSpaces(dataUser?.list_stats?.domains)}</Text>
							<Text >/{numberWithSpaces(dataUser?.subscription?.domains_max_limit)}</Text>
						</div>
						<div>
						{/* <Text style={{fontSize: '12px'}}>Записей: </Text> */}
					<Text>Доступно: {numberWithSpaces(dataUser?.subscription?.domains_max_limit - dataUser?.list_stats?.domains)}</Text>
</div>
					</div>
					<div>
						<Text>IP записи</Text>
						<div>
							<Text >{numberWithSpaces(dataUser?.list_stats?.ip_addresses)}</Text>
							<Text >/{numberWithSpaces(dataUser?.subscription?.ip_addresses_max_limit)}</Text>
						</div>
						<Text>Доступно: {numberWithSpaces(dataUser?.subscription?.ip_addresses_max_limit - dataUser?.list_stats?.ip_addresses)}</Text>

					</div>
					<div>
						<Text>url записи</Text>
						<div>
							<Text >{numberWithSpaces(dataUser?.list_stats?.urls)}</Text>
							<Text >/{numberWithSpaces(dataUser?.subscription?.urls_max_limit)}</Text>
						</div>
						<Text>Доступно: {numberWithSpaces(dataUser?.subscription?.urls_max_limit - dataUser?.list_stats?.urls)}</Text>

					</div>
					<div>
						<Text>hash записи</Text>
						<div>
							<Text >{numberWithSpaces(dataUser?.list_stats?.hashes)}</Text>
							<Text >/{numberWithSpaces(dataUser?.subscription?.hashes_max_limit)}</Text>
						</div>
						<Text>Доступно: {numberWithSpaces(dataUser?.subscription?.hashes_max_limit - dataUser?.list_stats?.hashes)}</Text>

					</div>

				</div>
			</Card>

		</>

	)
}


export default EdlLimitsNew;