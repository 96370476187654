import React from "react"
import styles from './UI.module.css'

function Dot({ color = "lime", size = "0.5em", margin = "0.25em" }) {
    return (
        <>
            <div
                className={styles.dot}
                style={{
                    marginInline: margin,
                    backgroundColor: color,
                    width: size,
                    height: size,
                }}></div>
        </>
    );
}

export default Dot;