import  {useEffect, useState} from "react";
//import styles from "./Clock.module.css";
function Clock(type) {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
      const timerId = setInterval(() => {
        setTime(new Date());
      }, 10000);
  
      return () => clearInterval(timerId);
    }, []);
  
    return type? convertDateToUTC(time).toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute:'2-digit'}): time.toLocaleTimeString('en-US', { hour12: false, hour: '2-digit', minute:'2-digit'})}
  
  export default Clock;

  function convertDateToUTC(date) 
  { return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); }