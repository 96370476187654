export function numberWithSpaces(x) {
    if (typeof x !== 'number' || isNaN(x) || !isFinite(x)) return "0";
    const str = x.toString();
    if (str.length < 7) {
      return x.toLocaleString('ru-RU');
    } else if (str.length < 10) {
      return str.slice(0, -6) + " млн";
    } else {
      return str.slice(0, -9) + " млрд";
    }
  }
