import React from 'react'
import { useState } from "react";
import styles from "./EdlStyles.module.css";
import { Button, Card, Space, Typography } from "antd";
import { IconCopy, IconEdit } from "../../icons";
import ModalChangePassword from "./ModalChangePassword";
import { useSelector } from "react-redux";
import { getEdlDataUser } from "../../store/edl/selectors";

const { Title, Text } = Typography;

function EdlSettingsNew({ permissions }) {
  const [modalChangePasswordOpen, setModalChangePasswordOpen] = useState(false);

  const dataUser = useSelector(getEdlDataUser);
  const [copyText, setCopyText] = useState(false);

  const dateEndStatus = (date, status) => {
    if (status?.includes("дн") && Number(status.split(" ")[0]) === 0)
      return (
        <span 
        className={styles.dataEndStatus + ' ' + styles.ended}>
          {date} ({status})
        </span>
      );
    else if (status?.includes("дн") && Number(status.split(" ")[0]) <= 31)
      return (
        <span className={styles.dataEndStatus + ' ' + styles.lastMonth}>
          {date} ({status})
        </span>
      );
    else
      return (
        <span className={styles.dataEndStatus + ' ' + styles.active}>
          {date} ({status})
        </span>
      );
  };

  const copyBufferText = (text, field) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopyText({ text: "Copy", field: field });
      })
      .catch(() => {
        setCopyText({ text: "Error", field: field });
      });
  };

  return (
    <>
      {modalChangePasswordOpen && (
        <ModalChangePassword
          open={modalChangePasswordOpen}
          close={() => setModalChangePasswordOpen(false)}
        />
      )}
      <Card>
        <Space
          className={styles.cardWrapper}
        >
          <Title level={4}>Http-авторизация устройств</Title>
          {permissions?.full_access && (
            <Button
              onClick={() => setModalChangePasswordOpen(true)}
              icon={<IconEdit />}
            >
              Изменить
            </Button>
          )}
        </Space>
        <div className={styles.httpDevices}>
          <Text>Домен</Text>
          <Text>{dataUser?.domain}</Text>
          <Text>Логин</Text>
          <Space size={12}>
            <Text className={styles.loginPasswordText}>{dataUser?.device_username}</Text>
            <Button
              size="small"
              type="text"
              onClick={() => copyBufferText(dataUser?.device_username, "login")}
              icon={<IconCopy className={styles.iconCopy} />}
            />
            <span
              style={
                copyText.text === "Error"
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {copyText.field === "login" ? copyText.text : ""}
            </span>
          </Space>
          <Text>Доступ до</Text>
          <Space size={12}>
            <Text>
              {dataUser?.is_trial ? (
                <span className={styles.active}>TRIAL</span>
              ) : (
                dateEndStatus(dataUser?.date_end, dataUser?.time_left_verbose)
              )}
            </Text>
            {/* <Text style={styleColorGray}>{dataUser?.time_left_verbose}</Text> */}
          </Space>

          <Text>Пароль</Text>
          <Space size={12}>
            <Text className={styles.loginPasswordText}>{dataUser?.device_password}</Text>
            <Button
              size="small"
              type="text"
              onClick={() =>
                copyBufferText(dataUser?.device_password, "password")
              }
              icon={<IconCopy className={styles.iconCopy} />}
            />
            <span
              style={
                copyText.text === "Error"
                  ? { color: "red" }
                  : { color: "green" }
              }
            >
              {copyText.field === "password" ? copyText.text : ""}
            </span>
          </Space>
        </div>
      </Card>
    </>
  );
}

export default EdlSettingsNew;
