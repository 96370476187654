import React, { useEffect } from "react";
import { Button, Card, Modal, Space, Typography } from "antd";
import { IconClose } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import {
  getValidatorApplySuccess,
  getValidatorData,
} from "../../store/edl/selectors";
import {
  applyEdlListValidators,
  switchValidateApplySuccess,
} from "../../store/edl";
import { getUser } from "../../store/auth/selectors";
import styles from "./EdlStyles.module.css";

function ModalCheckValidate({ open, close, name, validators, idList }) {
  const data = useSelector(getValidatorData);
  const userData = useSelector(getUser);
  const dispatch = useDispatch();
  const successValidate = useSelector(getValidatorApplySuccess);

  useEffect(() => {
    if (successValidate === true) {
      dispatch(switchValidateApplySuccess());
      close();
    }
  }, [successValidate]);
  const applyValidateChanges = () => {
    let body = {};
    if (userData?.first_name || userData?.last_name)
      body.updated_by = `${userData?.first_name} ${userData?.last_name}`;
    else body.updated_by = userData?.email;
    body.values_to_delete = data?.values_to_delete || [];
    body.validators = validators;
    dispatch(applyEdlListValidators({ idList, body }));
  };

  const changeMessage = () => {
    const arr = data.message.split("\n");
    return arr?.map((item) => <div key={item}>{item}</div>);
  };
  return (
    <Modal open={open} closable={false} footer={null} centered>
      <Button
        onClick={close}
        icon={<IconClose className={styles.addEdlListNewModalCloseIcon} />}
        type="ghost"
        className={styles.closeModalButton}
      />
      <Typography.Title level={2} className={styles.littleMarginBottom}>
        {name}
      </Typography.Title>
      <Card className={styles.successCard}>
        <Typography.Title level={5} className={styles.littleMarginBottom}>
          Проверка успешно завершена!
        </Typography.Title>
        {changeMessage()}
      </Card>
      <Space className={styles.middleMarginBottom}>
        <Button
          type="primary"
          onClick={close}
          className={styles.primaryButton + ' ' + styles.discardButton}
        >
          Отменить
        </Button>
        <Button
          type="primary"
          onClick={applyValidateChanges}
          className={styles.primaryButton + ' ' + styles.confirmButton}
        >
          Применить
        </Button>
      </Space>
    </Modal>
  );
}

export default ModalCheckValidate;
