import React, { useEffect } from "react";
import styles from "./OrgArea.module.css";
import { Space, Table, Typography, Spin } from "antd";
import Dot from "../UI/Dot";
import { useDispatch, useSelector } from "react-redux";
import { getLastUpdateValue, getOrgAssets } from "../../store/organisation";
import {
  getAssets,
  getAssetsLoader,
  getLastUpdate,
} from "../../store/organisation/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { getCollapsed } from "../../store/sidebar/selectors";

const { Text } = Typography;

function AssetListNew({
  openModal,
  search,
  currentPage,
  setCurrentPage,
  orgFilter,
  cityFilter,
  platformFilter,
  isFirstLoading,
  setIsFirstLoading
}) {
  const dispatch = useDispatch();
  const assets = useSelector(getAssets);
  const lastUpdateData = useSelector(getLastUpdate);
  const isLoading = useSelector(getAssetsLoader);
  const isSidebarCollapsed = useSelector(getCollapsed)

  const fetchData = () => {
    let filters = false;
    if (search || orgFilter || cityFilter || platformFilter) {
      filters = {
        search: search,
        organisation: orgFilter,
        platform: platformFilter,
        city: cityFilter,
      };
    }
    dispatch(getOrgAssets({ page: currentPage, filters: filters })).then(()=>{setIsFirstLoading(false)});
    
  };
  useEffect(() => {
    const timerRefresh = setTimeout(fetchData, 500);
    return () => {
      clearTimeout(timerRefresh);
    };
  }, [dispatch, currentPage, search, orgFilter, cityFilter, platformFilter]);

  useEffect(() => {
    dispatch(getLastUpdateValue());
  }, []);

  const getFormattedDate = (date) => {
    const now = new Date();
    const formatDate = date.split(" ");
    const reverseDate =
      formatDate[0].split(".").reverse().join("-") + " " + formatDate[1];
    const reqDate = new Date(reverseDate);
    return now.getTime() - reqDate.getTime();
  };
  const getConnectionStatus = (date, type) => {
    if (getFormattedDate(date) < 86400000)
      return (
        <div title="Все хорошо" className={styles.noWrapText}>
          <Dot color="#219653" />
          <Text className={styles.cellText}>
            {type} - {date}
          </Text>
        </div>
      );
    else if (86400000 < getFormattedDate(date) && getFormattedDate(date) < 604800000)
      return (
        <div title="Не подключалось более 1 дня">
          <Dot color="#FFBC36" />
          <Text className={styles.cellText}>
            {type} - {date}
          </Text>
        </div>
      );
    else if (getFormattedDate(date) > 604800000)
      return (
        <div title="Не подключалось более 7 дней">
          <Dot color="#EB5757" />
          <Text className={styles.cellText}>
            {type} - {date}
          </Text>
        </div>
      );
  };
  const getRmaFlag = (rma) => {
    if (rma === true)
      return <span className={styles.rmaFlag}>RMA</span>;
  };

  function renderMultilineTitle(title, subtitle) {
    return (
      <Space size={0} direction="vertical">
        <span>{title}</span>
        <span className={styles.subtitleText}>
          {subtitle ? `(по состоянию на: ${subtitle})` : "(обновлений не было)"}
        </span>
      </Space>
    );
  }
  const columns = [
    createColumn("SN", "serial_nr", (_, { serial_nr }) => {
      return (
        <div>
          <span>{serial_nr}</span>
        </div>
      );
    }),
    createColumn("Platform", "platform_name"),
    {
      title: renderMultilineTitle("Connection Status", lastUpdateData),
      key: `url_last_request`,
      dataIndex: ["url_last_request", "updates_last_request"],
      render: (_, row) => {
        return (
          <Text style={{ fontSize: "10px" }}>
            {row["url_last_request"] &&
              getConnectionStatus(row["url_last_request"], "URL")}
            {row["updates_last_request"] &&
              getConnectionStatus(row["updates_last_request"], "UPDATES")}
            {row["sandbox_last_active"] &&
              getConnectionStatus(row["sandbox_last_active"], "SANDBOX")}
          </Text>
        );
      },
    },
    {
      title: `Last Update`,
      key: `updates_last_update`,
      dataIndex: ["updates_last_update", "last_update_name"],
      render: (text, row) => {
        return (
          <div>
            {
              <Space direction="vertical" size={0}>
                <Text className={styles.cellText}>
                  {row["updates_last_update"]}
                </Text>
                <Text className={styles.cellText}>
                  {row["last_update_name"]}
                </Text>
              </Space>
            }
          </div>
        );
      },
    },

    createColumn(
      "Services",
      "subscriptions",
      (_, { subscriptions, service }) => (
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
          }}
        >
          {subscriptions.map((item) => (
            <React.Fragment key={item.slug + item.time_left_verbose}>
              <Text className={styles.cellText}>
                {item.slug} {item.time_left_verbose}
              </Text>
              <Text className={styles.cellText} style={{ color: "#7A7D86" }}>
                {item.time}
              </Text>
            </React.Fragment>
          ))}
          <Space direction="vertical" size={0}>
            <Text className={styles.cellText}>
              {service.length > 0 && service[0].status === "ACTIVE" ? (
                <span>
                  <Dot color="#219653" />
                  {service[0].slug}-{service[0].time_left_verbose}
                </span>
              ) : (
                <span>
                  <Dot color="#EB5757" /> Нет сервиса
                </span>
              )}
            </Text>
            <Text className={styles.cellText}>
              {service.length > 0 && service[0].status === "ACTIVE"
                ? getRmaFlag(service[0].rma)
                : ""}
            </Text>
          </Space>
        </div>
      )
    ),
    createColumn("End Date", "expires_next"),
    createColumn("Company", "organisation", (text)=>{
      return (
        <div className={styles.nameOverflow}>
          {text}
        </div>
      )
    }),
    {
      title: "Location",
      key: `Location`,
      dataIndex: ["city", "country"],
      render: (text, row) => {
        return (
          <div>
            {
              <Space direction="vertical" size={0}>
                <Text className={styles.cellText}>{row["city"]},</Text>
                <Text className={styles.cellText}>{row["country"]}</Text>
              </Space>
            }
          </div>
        );
      },
    },
  ];

  function createColumn(title, key, render) {
    return { title, key, dataIndex: key, render };
  }

  const customLoader = (
    <Spin className={styles.tableLoader} style={{left: isSidebarCollapsed? '50%' : '60%', top: isFirstLoading? '30%' : '50%'}} indicator={<PreloaderNew />} />
  );
  return (
    <div style={{ position: "relative" }}>
      <Table
        loading={{
          indicator: customLoader,
          spinning: isLoading
        }}
        columns={columns}
        dataSource={assets?.results}
        size="small"
        bordered={false}
        className={styles.table + " " + styles.tableClickable}
        rowKey={(obj) => obj.id}
        pagination={{
          position: "bottomRight",
          current: currentPage,
          defaultPageSize: 20,
          total: assets?.count,
          onChange: (page) => setCurrentPage(page),
          showSizeChanger: false
        }}
        scroll={{ x: 1000 }}
        onRow={(record) => ({
          onClick: () => openModal(record.id),
        })}
      ></Table>
    </div>
  );
}
export default AssetListNew;
