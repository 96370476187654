import React, { useState } from 'react'
import { Drawer, Button, Row, Typography, Checkbox, Col, Table, Badge, Input } from 'antd'
import { CloseOutlined, PlusOutlined, CheckOutlined } from '@ant-design/icons'
import { IconDelete } from '../../icons'
import styles from './Sandbox.module.css'
const { Text, Title } = Typography
function createColumn(title, key, width, render) {
    return { title, key, dataIndex: key, width, render };
}
export default function SettingsDrawer({ setOpenSettingsDrawer, openSettingsDrawer }) {
    const [emails, setEmails] = useState(['aputilin@styletele.com'])
    const [addingEmail, setAddingEmail] = useState(false);
    const [newEmail, setNewEmail] = useState(null);
    const [checkColor, setCheckColor] = useState('');
    const [closeColor, setCloseColor] = useState('');
    const testDataSource = [
        {
            date: '2024-09-09T12:00:34',
            log: 'Добавил aputilin@styletele.com',
            user_name: 'Александр Путилин',
            log_key: 2,
        },
        {
            date: '2024-09-10T14:25:46',
            log: 'Отключил уведомления',
            user_name: 'Константин Какунин',
            log_key: 1
        },
        {
            date: '2024-09-12T11:54:21',
            log: 'Включил уведомления',
            user_name: 'Сергей Матросов',
            log_key: 0,
        },
        {
            date: '2024-09-12T11:54:21',
            log: 'Удалил test@styletele.com',
            user_name: 'Сергей Матросов',
            log_key: 3
        },

    ]


    const count = Object.values(emails).reduce((acc, value) => {
        if (value !== 0 && value !== null && value !== undefined) {
            return acc + 1;
        }
        return acc;
    }, 0);
    const titleCloseIcon = (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>


            <Button
                icon={<CloseOutlined />}
                onClick={() => setOpenSettingsDrawer(false)}
                style={{
                    height: 32,
                    width: 32,
                    border: 'none',
                    borderRadius: '2px',
                    boxShadow: 'none',
                    background: '#F6F8FD'
                }}></Button>
        </div>)

    const changeDate = (date) => {
        if (date) {
            const formatDate = date?.split("T");
            const reverseDate =
                formatDate[0].split("-").reverse().join(".") +
                " " +
                formatDate[1].split(".")[0];
            return reverseDate;
        } else return null;
    };
    const getBadgeIcon = (key) => {
        switch (key) {
            case 0:
                return (
                    <div style={{ color: '#219653', backgroundColor: '#E0F6EA' }}>
                        <CheckOutlined />
                    </div>
                )
            case 1:
                return (
                    <div style={{ color: '#EB5757', backgroundColor: '#FFECEC' }}>
                        <CloseOutlined />
                    </div>
                )
            case 2:
                return (
                    <div style={{ color: '#327FEF', backgroundColor: '#EAF0FF' }}>
                        <PlusOutlined />
                    </div>
                )
            case 3:
                return (
                    <div style={{ color: '#E39800', backgroundColor: '#FFF5E0' }}>
                        <IconDelete />
                    </div>
                )

        }

    }

    const columns = [
        createColumn('Дата', 'date', '25%', (text) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{changeDate(text).split(' ')[0]}</Text>
                    <Text style={{ color: '#7A7D86' }}>{changeDate(text).split(' ')[1]}</Text>
                </div>
            )
        }),
        createColumn('Имя пользователя', 'user_name', '25%', (text) => {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Text>{text.split(' ')[0]}</Text>
                    <Text>{text.split(' ')[1]}</Text>
                </div>
            )
        }),
        createColumn('Лог', 'log', '50%', (text, row) => {
            return (
                <div style={{ display: 'flex', gap: 10, alignItems: 'start' }}>
                    <Badge style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '24px',
                        width: '24px',
                        justifyContent: 'center',
                        borderRadius: '2px',
                        marginTop: 6
                    }} count={getBadgeIcon(row?.log_key)} showZero />

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Text>{text.split(' ')[0]}</Text>
                        <Text>{text.split(' ')[1]}</Text>
                    </div>
                </div>
            )
        })
    ]

    const onSubmitForm = () => {
        emails.push(newEmail); 
        setAddingEmail(false);
        setNewEmail('')
    }
    return (
        <Drawer
            open={openSettingsDrawer}
            width={'50%'}
            style={{ padding: '0px 30px 0px 30px' }}
            onClose={() => { setOpenSettingsDrawer(false) }}
            maskClosable
            closeIcon={false}
        >
            {titleCloseIcon}
            <Row>
                <Title style={{ color: '#333333', fontSize: 28, marginBottom: 24 }} level={3}>Настройки и оповещения</Title>
            </Row>
            <Row>
                <Title style={{ color: '#4B4F55', fontSize: 16, marginBottom: 16 }} level={5}>Текущий лимит сервиса для пользователя</Title>
            </Row>
            <Row style={{ marginBottom: 20 }}>
                <div style={{
                    display: 'flex', justifyContent: 'space-between', border: '1px solid #E9EEF7', borderRadius: 4,
                    width: '45%', height: 40, alignItems: 'center', color: '#C1C2C9', padding: '10px 16px 10px 16px'
                }}>
                    <span>
                        Unlimited
                    </span>
                    <span>
                        /сутки
                    </span>
                </div>
            </Row>
            <Row >
                <div style={{
                    display: 'flex', flexDirection: 'column', marginBottom: '17px'
                }}>
                    <Title style={{ color: '#4B4F55', fontSize: 16, marginBottom: 16 }} level={5}>Оповещения</Title>
                    <Checkbox style={{ fontSize: 14, fontWeight: 400, color: '#4B4F55' }}>Получать оповещения о вредоносных файлах на email</Checkbox>
                </div>
            </Row>
            <Row style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <div>
                    <Title style={{ color: '#333333', fontSize: 14, fontWeight: 400 }} level={5}>E-mail:</Title>
                    {emails?.map((email) => {
                        return (
                            <div key={email} style={{ display: 'flex', gap: 10, color: '#8090B8', marginBottom: 10 }}>
                                <div style={{
                                    width: '80%', border: '1px solid #E9EEF7', borderRadius: 4,
                                    height: 40, alignContent: 'center', color: '#4B4F55', padding: '0px 16px 0px 16px'
                                }}>
                                    {email}
                                </div>
                                <IconDelete onClick={() => { setEmails(emails.filter(element => element !== email)) }} style={{ cursor: 'pointer' }} />
                            </div>
                        )
                    }
                    )}
                    {addingEmail &&
                        <div style={{ height: 40, display: 'flex', gap: 10, color: '#8090B8' }}>
                            <Input style={{width:'80%'}} value={newEmail} onChange={(e) => { setNewEmail(e.target.value) }} />
                            <CheckOutlined style={{
                                cursor: 'pointer',
                                color: checkColor,
                            }} onClick={()=>onSubmitForm()}
                                onMouseEnter={() => { setCheckColor('#219653') }}
                                onMouseLeave={() => { setCheckColor('') }}
                            />
                            <CloseOutlined style={{
                                cursor: 'pointer',
                                color: closeColor,
                            }} onClick={() => { setNewEmail(''); setAddingEmail(false) }}
                                onMouseEnter={() => { setCloseColor('#EB5757') }}
                                onMouseLeave={() => { setCloseColor('') }}
                            />
                        </div>
                    }
                </div>
                <div>
                    {count < 5 && <Button className={styles.settingsBtn} style={{ height: 40, width: '170px' }} onClick={() => { setAddingEmail(true) }} icon={<PlusOutlined />} >Добавить E-mail</Button>}
                    {count === 5 && <span style={{ color: '#4B4F55' }}>Вы можете добавить не более 5 емейлов</span>}
                </div>
                <Row gutter={10} style={{ width: '82%' }}>
                    <Col span={14}>
                        <Button className={styles.sandboxSubmitBtn} style={{ height: 40, width: '100%' }} >Сохранить изменения</Button>
                    </Col>
                    <Col span={10}>
                        <Button className={styles.sandboxDeclineBtn} style={{ height: 40, width: '100%' }} >Отменить</Button>
                    </Col>
                </Row>
                <Row style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Title style={{ color: '#4B4F55', fontSize: 16, marginBottom: 16 }} level={5}>Логи изменения настроек оповещения</Title>
                    <Table
                        showHeader={false}
                        columns={columns}
                        dataSource={testDataSource}
                        rowKey={(row)=> row.date + row.log}
                    />


                </Row>
            </Row>
        </Drawer>
    )
}