import React from "react";
import { useState } from "react";
import {
  Button,
  ConfigProvider,
  Form,
  Input,
  Select,
  Space,
  Typography,
  message,
  Alert,
} from "antd";
import { IconNotFound } from "../../icons/IconNotFound";
import ThreatVaultModal from "./ThreatVaultModal";
import { useDispatch, useSelector } from "react-redux";
import WithoutAuthorization from "../../components/WithoutAuthorization";
import {
  getDataArrayThreat,
  getDataArrayThreatSha,
  //getDataIdThreat,
} from "../../store/threatvault";
import {
  getTVaultArrData,
  getTVaultArrDataSha,
  getTvaultErrors,
  getTvaultLoader,
} from "../../store/threatvault/selectors";
import ThreatVaultCardCveNew from "./ThreatVaultCardCveNew";
import ThreatVaultCardShaNew from "./ThreatVaultCardShaNew";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import styles from './ThreatVaultModal.module.css'

const { Title, Text } = Typography;

function ThreatVaultAreaNew({ permissions }) {
  const [, contextHolder] = message.useMessage();
  const isLoader = useSelector(getTvaultLoader);
  const errors = useSelector(getTvaultErrors);
  const dispatch = useDispatch();
  const [type, setType] = useState("id");

  const [modalItem, setModalItem] = useState(-1);
  const threatArrData = useSelector(getTVaultArrData);
  const threatArrDataSha = useSelector(getTVaultArrDataSha);

  const changeSelectType = (value) => {
    const lowerCaseValue = value.toLowerCase().trim();
    if (!isNaN(lowerCaseValue)) setType("id");
    if (lowerCaseValue.includes("cve")) setType("cve");
    if (lowerCaseValue.length === 32) setType("md5");
    if (lowerCaseValue.length === 64) setType("sha256");
  };

  // const getItemById = async (itemID) => {
  //   dispatch(getDataIdThreat(itemID));
  //   setModalItem(itemID);
  // };

  return (
    <>
      <Space direction="vertical" size={20} className={styles.threatVaultAreaWrapper}>
        {contextHolder}

        <Title level={3} className={styles.noMargin}>
          Threat Vault
        </Title>
        {(permissions?.full_access || permissions?.read_access) && (
          <>
            {errors && errors !== "По вашему запросу ничего не найдено." && (
              <Alert
                message="Error"
                description={errors}
                type="error"
                showIcon
              />
            )}
            <ConfigProvider
              theme={{
                components: {
                  Select: { controlHeight: 44 },
                  Input: { controlHeight: 44 },
                  Button: { controlHeight: 44 },
                  Form: { margin: 0 },
                },
              }}
            >
              <Form
                requiredMark={false}
                layout="inline"
                className={styles.threatVaultSearchForm}
                onFinish={({ search }) => {
                  if (type === "id" || type === "cve") {
                    dispatch(getDataArrayThreat({ name: search, type: type }));
                  } else
                    dispatch(
                      getDataArrayThreatSha({ name: search, type: type })
                    );
                }}
                initialValues={{ type }}
              >
                {isLoader && <PreloaderNew />}
                <Form.Item>
                  <Text>Поиск</Text>
                  <Form.Item
                    name="search"
                    data-testid= 'search'
                    rules={[
                      {
                        required: true,
                        message: "Пожалуйста, введите значение!",
                      },
                    ]}
                  >
                    <Input
                      onChange={(evt) => changeSelectType(evt.target.value)}
                      placeholder="Например: 38419 или CVE-2002-1310"
                    />
                  </Form.Item>
                </Form.Item>
                <Form.Item>
                  <Text>Тип</Text>
                  <Select
                  data-testid='selector'
                    onChange={(evt) => {
                      setType(evt);
                    }}
                    className={styles.threatVaultSearchFormSelector}
                    value={type}
                    options={[
                      { value: "id", label: "ID Сигнатуры" },
                      { value: "cve", label: "Номер CVE" },
                      { value: "sha256", label: "sha256" },
                      { value: "md5", label: "Md5" },
                    ]}
                  />
                </Form.Item>
                <Form.Item className={styles.threatVaultSearchFormButtonWrapper}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className={styles.threatVaultSearchFormButton}
                    disabled={isLoader ? true : false}
                  >
                    Поиск
                  </Button>
                </Form.Item>
              </Form>
            </ConfigProvider>

            {errors === "По вашему запросу ничего не найдено." && (
              <Space
                direction="vertical"
                size={24}
                className={styles.threatVaultNotFoundMessage}
              >
                <IconNotFound />
                <div>
                  По вашему запросу ничего не найдено. Попробуйте другой запрос
                </div>
              </Space>
            )}
            {threatArrData && (
              <ThreatVaultCardCveNew
                data={threatArrData?.result}
                //getItemById={getItemById}
                setModalItem={setModalItem}
              />
            )}
            {threatArrDataSha && (
              <ThreatVaultCardShaNew
                data={threatArrDataSha?.result}
                //getItemById={getItemById}
                setModalItem={setModalItem}
              />
            )}
            <ThreatVaultModal
              open={modalItem !== -1}
              close={() => setModalItem(-1)}
              data={modalItem}
            />
          </>
        )}
      </Space>
      {permissions?.full_access === false &&
        permissions?.read_access === false && <WithoutAuthorization />}
    </>
  );
}

export default ThreatVaultAreaNew;
