import React from "react";
import styles from "./SetPasswordModal.module.css";
import { Button, Form, Input, Alert, Space, Typography } from "antd";
import { IconClose } from "../../../icons/IconClose";
import { useDispatch, useSelector } from "react-redux";
import PreloaderNew from "../PreloaderNew/PreloaderNew";
import { setPassword } from "../../../store/auth";
import { getChangePassLoader, getError } from "../../../store/auth/selectors";

const { Title } = Typography;

function SetPasswordForm({ close }) {
  const isLoading = useSelector(getChangePassLoader);
  const errors = useSelector(getError);
  const dispatch = useDispatch();
  return (
    <>
      <Space
        className={styles.setPasswordFormHeaderWrapper}
      >
        <Button
          onClick={close}
          icon={<IconClose className={styles.closeSetPasswordFormIcon} />}
          type="ghost"
          className={styles.closeSetPasswordFormButton}
        />
      </Space>

      <div
        className={styles.setPasswordFormWrapper}
      >
        {isLoading && <PreloaderNew />}
        <Title className={styles.setPasswordFormTitle} level={2}>
          Изменить пароль
        </Title>
        <Form
          size="large"
          name="auth"
          layout="vertical"
          requiredMark={false}
          className={styles.form}
          onFinish={({ passwordNew, passwordOld }) => {
            dispatch(
              setPassword({
                new_password: passwordNew,
                current_password: passwordOld,
              })
            );
          }}
        >
          {errors && <Alert message={errors} type="error" showIcon />}
          {/* {isLoading && <PreloaderNew />} */}
          <Form.Item
            label="Старый Пароль"
            name="passwordOld"
            hasFeedback
            className={styles.input}
            rules={[
              { required: true, message: "Пожалуйста, введите старый пароль" },
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item
            label="Новый Пароль"
            name="passwordNew"
            hasFeedback
            className={styles.input}
            rules={[
              { required: true, message: "Пожалуйста, введите новый пароль" },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Повторите Пароль"
            name="passwordNewConfirm"
            dependencies={["passwordNew"]}
            hasFeedback
            className={styles.input}
            rules={[
              {
                required: true,
                message: "Пожалуйста, повторите новый пароль!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("passwordNew") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Пароли не совпадают!"));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Space
            className={styles.setPasswordFormButtonsWrapper}
          >
            <Button
              type="primary"
              onClick={close}
              className={styles.setPasswordFormDeclineButton}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading ? true : false}
              className={styles.setPasswordFormApproveButton}
            >
              Изменить
            </Button>
          </Space>
        </Form>{" "}
      </div>
    </>
  );
}

export default SetPasswordForm;
