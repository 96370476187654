import React, { useEffect } from "react";
import styles from "./PasswordConfirmNew.module.css";
import background_image from "../../../images/loginBackground.jpg";
import { useSelector, useDispatch } from "react-redux";
import { resetPasswordConfirm } from "../../../store/auth";
import {
  getChangePassLoader,
  getError,
  getIsSuccessChangePassword,
} from "../../../store/auth/selectors";
import { Form, Input, Button, Spin, Alert, Typography } from "antd";
import { getUrlLoaders } from "../../../store/urlfilter/selectors";
import PreloaderNew from "../PreloaderNew/PreloaderNew";
import { useNavigate, useParams } from "react-router-dom";

const { Title } = Typography;

function PasswordConfirmNew() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const errors = useSelector(getError);
  const isLoader = useSelector(getUrlLoaders);
  const isSuccess = useSelector(getIsSuccessChangePassword);
  const isLoading = useSelector(getChangePassLoader);

  useEffect(() => {
    if (isSuccess)
      setTimeout(() => {
        navigate("/login");
      }, 3000);
  }, [isSuccess]);

  return (
    <Spin size="large" spinning={isLoader}>
      <div
        className={styles.passwordConfirmWrapper}
      >
        <img
          src={background_image}
          className={styles.backgroundImage}
          alt="background"
        />
        <div
          className={styles.passwordConfirmFormWrapper}
        >
          <Title level={2} className={styles.passwordConfirmFormTitle}>
            Изменить пароль
          </Title>

          {errors &&
            errors?.map((error) => (
              <Alert
                key={error + "r"}
                message="Authorization Error"
                description={error}
                type="error"
                showIcon
              />
            ))}
          <Form
            size="large"
            name="auth"
            layout="vertical"
            requiredMark={false}
            className={styles.form}
            onFinish={({ passwordNew, passwordNewConfirm }) => {
              dispatch(
                resetPasswordConfirm({
                  uid: params.uid,
                  token: params.token,
                  new_password: passwordNew,
                  re_new_password: passwordNewConfirm,
                })
              );
            }}
          >
            {isLoading && <PreloaderNew />}
            <Form.Item
              label="Новый Пароль"
              name="passwordNew"
              hasFeedback
              rules={[
                { required: true, message: "Пожалуйста, введите пароль" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              label="Повторите Пароль"
              name="passwordNewConfirm"
              dependencies={["passwordNew"]}
              hasFeedback
              rules={[
                { required: true, message: "Пожалуйста, повторите пароль!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passwordNew") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Пароли не совпадают!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <div
                className={styles.passwordConfirmGoToLoginWrapper}
              >
                <div className={styles.passwordConfirmGoToLoginDivider} />
                <Button
                  type="link"
                  size="small"
                  className={styles.passwordConfirmGoToLogin}
                  href="/login"
                >
                  Вход на портал
                </Button>
              </div>
            </Form.Item>

            <Form.Item className={styles.passwordConfirmButtonWrapper}>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading ? true : false}
                className={styles.passwordConfirmButton}
              >
                Изменить
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Spin>
  );
}

export default PasswordConfirmNew;
