// export async function getFileHash(file) {
//     const buffer = await file.arrayBuffer();
//     const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
//     const hashArray = Array.from(new Uint8Array(hashBuffer));
//     const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
//     return hashHex;
// }
export const getSourceIn=(val, source)=> {

    if (val ==='API') return '01'
    else if ((!val || val?.length===0) && source) return null
    else if (val && source) return source
      }

export function parseIfUserName(org, orgId_userId){
    const regex = /\d+_+\d+/
    let userName = "Не найдено"
    if (regex.test(orgId_userId)){
        org?.users?.forEach((user)=>{
            if (user?.id?.toString() === orgId_userId.split('_')[1]){
                userName = '';
                if (user?.first_name){
                    userName = user?.first_name + ' '
                }
                if (user?.last_name){
                    userName = userName + user?.last_name
                }
            }           
        })
        return userName
    }
    else if(orgId_userId?.length === 64) {
        return 'API'
    }
    else  return orgId_userId;

}


export const getDayOfTheWeek = (date) => {
    const newDate = new Date(date)
    const dayOfWeek = newDate.getDay();
    switch (dayOfWeek) {
        case 0:
            return 'Вс'
        case 1:
            return 'Пн'
        case 2:
            return 'Вт'
        case 3:
            return 'Ср'
        case 4:
            return 'Чт'
        case 5:
            return 'Пт'
        case 6:
            return 'Сб'
    }
}