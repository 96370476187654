import React, { useEffect } from 'react'
import styles from './Sandbox.module.css'
import { getUrlData, getCategoryErrors } from '../../store/sandbox/selectors';
import { getCheckUrl } from '../../store/sandbox';
import { Table, message, Row, Col, Typography} from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { getIpLoader, getUrlLoader } from '../../store/sandbox/selectors';
import { changeKeyToTitle, getTableDataset, checkTableColumns, parseValue } from './utils';
const { Title } = Typography
export default function FileInfo({ detailsData, tableData }) {
    const dispatch = useDispatch()
    const urlLoader = useSelector(getUrlLoader)
    const ipLoader = useSelector(getIpLoader)
    const urlData = useSelector(getUrlData)
    const categoryErrors = useSelector(getCategoryErrors)
    useEffect(() => {
        if (detailsData?.file_url && detailsData?.file_url !== 'unknown') {
            dispatch(getCheckUrl(detailsData?.file_url))
        }
    }, [])

    const [messageApi, contextHolder] = message.useMessage();


    return (
        <div>
            {contextHolder}
            <Row>
                <Title className={styles.detailsDrawerTableTitle} level={5}>Информация по файлу</Title>
            </Row>
            <Row >
                <Col span={12} >
                    {Object.keys(detailsData).map(key => {
                            return (
                                detailsData[key] !== null && (
                                    <Row className={styles.fileInfoDrawerItem} key={key}>
                                        <Col span={9} className={styles.detailsDrawerDetailsTitle}>
                                            {changeKeyToTitle(key)}
                                        </Col>
                                        <Col span={15} className={styles.detailsDrawerDetailsValues}>
                                            {parseValue(key, detailsData[key], urlLoader, urlData, ipLoader, categoryErrors, dispatch, messageApi, null, detailsData)}
                                        </Col>
                                    </Row>)
                            )
                        }
                    )}
                </Col>
            </Row>
            <Row>
                <Title className={styles.detailsDrawerTableTitle} level={5}>Таблица проверок</Title>
            </Row>
            <Table
                className={styles.table + ' ' +styles.tableFontWeight}
                columns={checkTableColumns(detailsData, dispatch)}
                dataSource={getTableDataset(tableData)}
                pagination={false}
                rowKey={(obj) => obj?.source + 'detailsHash'}
            /></div>
    )
}