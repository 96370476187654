export const getSandboxData = ({ sandbox }) => sandbox.sandboxData;
export const getUrlCheckErrors = ({ sandbox }) => sandbox.errors;
export const getDetailsErrors = ({sandbox}) => sandbox.detailsErrors;
export const getSampleErrors = ({sandbox}) => sandbox.sampleError;
// export const getSandboxStatsData = ({ sandbox }) => sandbox.sandboxStats;
export const getSandboxLoaders = ({ sandbox }) => sandbox.loaders.common;

export const getSandboxLTotalForTable = ({ sandbox }) => sandbox.sandboxDataTotal;
export const getSandboxGraphLoader = ({ sandbox }) => sandbox.loaders.graphs;
export const getSandboxTableLoader = ({ sandbox }) => sandbox.loaders.table;
export const getSandboxDetailsLoader = ({ sandbox }) => sandbox.loaders.details;
export const getSandboxHashLoader = ({ sandbox }) => sandbox.loaders.hashChecker;
export const getSandboxMyAssetsDrop = ({ sandbox }) => sandbox.sandboxAssets;
export const getSandboxFileErrors = ({ sandbox }) => sandbox.fileError;
export const getSandboxNextPage = ({ sandbox }) => sandbox.nextPage;
export const getSandboxOverallStats = ({ sandbox }) => sandbox.sandboxOverallStatistics
export const getSandboxOverallLoader = ({sandbox}) =>sandbox.loaders.overall
export const getAllFilesGraphData = ({ sandbox }) => sandbox.allFilesGraphData
export const getSuspiciousFilesGraphData = ({ sandbox }) => sandbox.suspiciousFilesGraphData
export const getFileTypesGraphData = ({ sandbox }) => sandbox.fileTypesGraphData
export const getSandboxReqTableData = ({ sandbox }) => sandbox.sandboxRequestsTableData;
export const getReqDetails = ({ sandbox }) => sandbox.sandboxRequestDetails
export const getHashDetails = ({sandbox}) => sandbox.hashCheckDetails
export const getHashErrors = ({sandbox}) =>sandbox.hashCheckerErrors

export const getUrlData = ({sandbox})=>sandbox.urlData;
export const getIpData = ({sandbox})=>sandbox.ipData;
export const getIpLoader = ({sandbox})=>sandbox.loaders.ipLoader;
export const getUrlLoader = ({sandbox})=>sandbox.loaders.urlLoader;
export const getCategoryErrors = ({sandbox})=>sandbox.categoryErrors;

export const getFileUploadingProgress = ({sandbox})=>sandbox.fileChecker.progress;
export const getIsFileUploadingInProgress = ({sandbox})=>sandbox.fileChecker.loading;
export const getFileUploadingErrors = ({sandbox}) => sandbox.fileChecker.fileUploadErrors;
export const getFileHash = ({sandbox})=> sandbox.fileChecker.sha256;
export const getIsFinished = ({sandbox})=>sandbox.fileChecker.finished


