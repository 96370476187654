import React from "react";
import { Col, Row, Card, Typography, Button } from "antd";
import { useNavigate } from "react-router-dom";
import styles from './WithoutAuthorization.module.css'

export default function WithoutAuthorization() {
    const { Title } = Typography;
const navigate = useNavigate()
    return (
        <>
            <Row>
                <Col span={24}>
                    <Card
                        className={styles.card}
                    >
                        <Title level={3} className={styles.title}>
                            У Вас нет прав для просмотра данного раздела!
                        </Title>
                        <div className={styles.cardContent}>
                        <Button  className={styles.button} type='primary' onClick={()=> navigate('dashboards')}>На главную</Button></div>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
