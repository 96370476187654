// eslint-disable react-hooks/exhaustive-deps
import React from "react";
import styles from "./EdlStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import {
  getCheckListsInstance,
  setProgress,
  switchAddListSuccess,
  switchAddSuccess,
} from "../../store/edl";
import {
  getEdlAddInstanceErrors,
  getEdlAddLoaders,
  getEdlSuccessAddList,
} from "../../store/edl/selectors";

import { getUser } from "../../store/auth/selectors";
import { Alert, Button, Form, Input, Typography } from "antd";

import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconClose } from "../../icons";
const { TextArea } = Input;

function AddEdlLInstanceNew({ close, item }) {
  const { Title } = Typography;
  const type = item?.list_type;
  const dispatch = useDispatch();
  const addSuccess = useSelector(getEdlSuccessAddList);
  const errors = useSelector(getEdlAddInstanceErrors);
  const userData = useSelector(getUser);
  const isLoading = useSelector(getEdlAddLoaders);
  const saveInstance = async ({ value, comment }) => {
    const data = {};
    if (userData?.first_name || userData?.last_name)
      data.updated_by = `${userData?.first_name} ${userData?.last_name}`;
    else data.updated_by = `${userData?.email}`;
    if (comment) data.records = [{ value: value, comment: comment }];
    else data.records = [{ value: value }];
    dispatch(
      getCheckListsInstance({ data: data, idList: item.id, typeList: "value" })
    );
  };
  const resetAll = () => {
    dispatch(switchAddSuccess());
    dispatch(setProgress(0));
    close();
    dispatch(switchAddListSuccess());
  };

  const handlePlaceHolder = () => {
    let placeholder = "";

    // eslint-disable-next-line default-case
    switch (type) {
      case "IP_ADDRESS_LIST": {
        placeholder =
          "192.168.1.15, 192.168.20.10/32, 001:db8:123:1::/64, 192.168.20.40-192.168.20.50";
        break;
      }
      case "DOMAIN_LIST": {
        placeholder = "company.com, *.company.com, ^eng.company.com";
        break;
      }
      case "URL_LIST": {
        placeholder = "example.com/*, google.^.com, url.com/cats, 192.168.0.1";
        break;
      }
    }

    return placeholder;
  };

  return (
    <>
      <Button
        onClick={resetAll}
        icon={<IconClose className={styles.addEdlListNewModalCloseIcon} />}
        type="ghost"
        className={styles.closeModalButton}
      />
      <Title className={styles.modalTitle} level={2}>
        Добавить запись
      </Title>

      <Form
        size="large"
        name="AddList"
        layout="vertical"
        requiredMark={false}
        className={styles.form + ' ' + styles.formWrapper}
        onFinish={({ InstName, InstDesc }) => {
          saveInstance({ value: InstName, comment: InstDesc });
        }}
      >
        {isLoading && <PreloaderNew />}
        {errors && <Alert message={errors} type="error" showIcon />}

        {addSuccess && (
          <Alert
            message="Success"
            description="Запись успешно добавлена "
            type="success"
            showIcon
          ></Alert>
        )}
        {
          <Form.Item
            className={styles.input + ' ' + styles.inputWrapper}
            name="InstName"
            label={"Значение записи"}
            rules={[
              { required: true, message: "Пожалуйста, введите значение" },
            ]}
          >
            <Input style={{ height: "44px" }} disabled={addSuccess} />
          </Form.Item>
        }

        <div
          className={styles.placeholderWrapper + ' ' + styles.modalTitle}
        >
          <span className={styles.placeholderText}>{handlePlaceHolder()}</span>
        </div>
        <Form.Item
          className={styles.input + ' ' + styles.inputWrapper}
          name="InstDesc"
          label={"Комментарий"}
        >
          <TextArea
            rows={4}
            placeholder="Максимум 255 символов"
            maxLength={255}
            disabled={addSuccess}
          />
        </Form.Item>
        {!addSuccess && (
          <div
            className={styles.buttonsAreaWrapper}
          >
            <Button
              type="primary"
              onClick={close}
              className={styles.primaryButton + ' ' + styles.discardButton}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              className={styles.primaryButton + ' ' + styles.confirmButton}
            >
              Добавить
            </Button>
          </div>
        )}
        {addSuccess && (
          <div
            className={styles.buttonsAreaWrapper + ' ' + styles.justifyContentCenter}
          >
            <Button
              type="primary"
              onClick={() => {
                dispatch(switchAddSuccess());
                close();
                dispatch(switchAddListSuccess());
              }}
              className={styles.primaryButton + ' ' + styles.confirmButton}
            >
              Закрыть
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
export default AddEdlLInstanceNew;
