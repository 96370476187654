import React from "react";
import { Alert, Button, Modal, Typography } from "antd";
import { IconError } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import {
  patchKfeedMode,
  resetKfeedErrors,
  switchKfeedSuccess,
} from "../../store/urlfilter";
import {
  getKfeedErrors,
  getKfeedSucess,
  getUrlLoaders,
} from "../../store/urlfilter/selectors";
import styles from "./UrlFilterNew.module.css";

function ModalConfirmURL({ open, close, item }) {
  const dispatch = useDispatch();
  const isSuccess = useSelector(getKfeedSucess);
  const errors = useSelector(getKfeedErrors);
  const isLoading = useSelector(getUrlLoaders);

  const handleClose = () => {
    dispatch(switchKfeedSuccess());
    close();
  };
  const switchKfeedMode = (item) => {
    let params = {};
    if (item?.fallbacks?.length > 0) params.fallbacks = [];
    else params.fallbacks = [1];
    dispatch(patchKfeedMode({ id: item?.equipment_id, fallbacks: params }));
  };
  return (
    <Modal open={open} closable={false} footer={null} centered>
      <div
        className={styles.modalConfirmWrapper}
      >
        {isLoading && <PreloaderNew />}
        {errors && (
          <Alert
            Alert
            message={errors}
            type="error"
            key={+"error"}
            showIcon
            icon={<IconError />}
            closable
          ></Alert>
        )}
        {!isSuccess && (
          <Typography.Title level={3}>
            {
              "Вы уверены, что хотите переключить режим работы  KFEED PLUGIN для данного устройства?"
            }
          </Typography.Title>
        )}
        {isSuccess && (
          <Typography.Title level={3}>Успешно выполнено!</Typography.Title>
        )}
        {!isSuccess && (
          <div
            className={styles.modalConfirmButtonsArea}
          >
            <Button
              onClick={() => {
                dispatch(resetKfeedErrors());
                close();
              }}
              className={styles.modalConfirmButton}
              htmlType="reset"
            >
              Отмена
            </Button>
            <Button
              // onClick={() => {
              // 	close();
              // 	setModalSuccessOpen(true);
              // }}
              className={styles.modalConfirmButtonSuccess}
              type="primary"
              onClick={() => switchKfeedMode(item)}
            >
              {"Применить"}
            </Button>
          </div>
        )}
        {isSuccess && (
          <Button
            // onClick={() => {
            // 	close();
            // 	setModalSuccessOpen(true);
            // }}
            className={styles.modalConfirmButtonSuccess}
            type="primary"
            onClick={() => {
              handleClose();
            }}
          >
            {"Закрыть"}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ModalConfirmURL;
