// eslint-disable react-hooks/exhaustive-deps
import { useState } from "react";
import styles from "./EdlStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import {
  getCheckListName,
  getCheckListsLimit,
  getFile,
  setProgress,
  switchAddListSuccess,
} from "../../store/edl";
import {
  getEdlAddErrors,
  getEdlAddLoaders,
  getEdlDataUser,
  getEdlSuccessAddList,
  getFileProgress,
} from "../../store/edl/selectors";

import { getUser } from "../../store/auth/selectors";
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Progress,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from "antd";
import { IconDownload } from "../../icons/IconDownload";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { IconClose } from "../../icons";

function AddEdlListNew({ close }) {
  const { Title, Text } = Typography;
  const [type, setType] = useState("DOMAIN_LIST");
  const [file, setFile] = useState(null);
  const dispatch = useDispatch();
  const addSuccess = useSelector(getEdlSuccessAddList);
  const errors = useSelector(getEdlAddErrors);
  const userData = useSelector(getUser);
  const userDataEdl = useSelector(getEdlDataUser);
  const [fileError, setFileError] = useState(false);
  const progress = useSelector(getFileProgress);
  const isLoading = useSelector(getEdlAddLoaders);
  const [active, setActive] = useState(true);
  const [validators, setValidators] = useState([]);
  const validatorsType = [
    { value: "NO", label: "No validation" },
    { value: "PAN", label: "Palo Alto Networks" },
    { value: "FORTINET", label: "Fortinet" },
    //{value: 'CP', label: 'CheckPoint', disabled: true}
  ];
  // useEffect(() => {
  //     if (addSuccess === true) { setTimeout(() => close(), 3000) }
  // }, [addSuccess]);

  const saveList = async (name) => {
    // event.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("is_active", active);
    if (file) {
      formData.append("file", file);
    }

    if (userData?.first_name || userData?.last_name)
      formData.append(
        "created_by",
        `${userData?.first_name} ${userData?.last_name}`
      );
    else formData.append("created_by", `${userData?.email}`);
    if (userData?.first_name || userData?.last_name)
      formData.append(
        "updated_by",
        `${userData?.first_name} ${userData?.last_name}`
      );
    else formData.append("updated_by", `${userData?.email}`);

    formData.append("list_type", type);
    if (validators.length > 0) {
      for (let i = 0; i < validators.length; i = i + 1) {
        if (validators[i] !== "NO")
          formData.append(`validators`, validators[i]);
      }
    }
    // if (!fileError) dispatch(addEdlList(formData))
    if (!file) dispatch(getCheckListName({ name: name, dataList: formData }));
    else {
      if (!fileError)
        dispatch(getCheckListsLimit({ name: name, dataList: formData }));
    }
  };
  const handleFormLabel = (type) => {
    let formLabel = "";
    // eslint-disable-next-line default-case
    switch (type) {
      case "IP_ADDRESS_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.ip_addresses_limits.active
            : userDataEdl?.list_stats?.ip_addresses_limits.inactive;
        break;
      }
      case "DOMAIN_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.domains_limits.active
            : userDataEdl?.list_stats?.domains_limits.inactive;
        break;
      }
      case "URL_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.urls_limits.active
            : userDataEdl?.list_stats?.urls_limits.inactive;
        break;
      }
      case "HASH_LIST": {
        formLabel =
          active === true
            ? userDataEdl?.list_stats?.hashes_limits.active
            : userDataEdl?.list_stats?.hashes_limits.inactive;
        break;
      }
    }
    return formLabel;
  };
  const validateFile = (type, stringAmount, active) => {
    let result = false;
    if (stringAmount <= 200000) {
      // eslint-disable-next-line default-case
      switch (type) {
        case "IP_ADDRESS_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.ip_addresses_limits.active
              : userDataEdl?.list_stats?.ip_addresses_limits.inactive)
          )
            result = true;
          break;
        }
        case "DOMAIN_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.domains_limits.active
              : userDataEdl?.list_stats?.domains_limits.inactive)
          )
            result = true;
          break;
        }
        case "URL_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.urls_limits.active
              : userDataEdl?.list_stats?.urls_limits.inactive)
          )
            result = true;
          break;
        }
        case "HASH_LIST": {
          if (
            stringAmount <=
            (active === true
              ? userDataEdl?.list_stats?.hashes_limits.active
              : userDataEdl?.list_stats?.hashes_limits.inactive)
          )
            result = true;
          break;
        }
      }
    }
    return result;
  };

  const handleDownloadEDL = () => {
    // const url = `${userDataEdl?.documentation?.txt_upload_file}`;
    // window.open(url, '_blank');
    dispatch(
      getFile({
        url: userDataEdl?.documentation?.txt_upload_file,
        name: "Upload-Example",
      })
    );
  };

  function placeFileContent(file, type) {
    const content = readFileContent(file)
      .then((content) => {
        return validateFile(type, content.split(/\r\n|\r|\n/).length);
      })
      .catch((error) => console.log(error, "error"));
    return content;
  }

  function readFileContent(file) {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = (event) => resolve(event.target.result);
      reader.onerror = (error) => reject(error);
      reader.readAsText(file);
    });
  }
  const switchType = async (file, type) => {
    if (!file) return;
    else {
      const validate = await placeFileContent(file, type);
      if (file.size <= 52428800 && validate) {
        setFile(file);
      } else setFileError(true);
    }
  };
  const handleChange = (value) => {
    const index = value.indexOf("NO");

    if (index === 0 && value.length > 1) {
      const arr = value.filter((item) => item !== "NO");
      setValidators(arr);
    } else if (index === 0 && value.length === 1) setValidators(value);
    else if (index !== 0 && index !== -1 && value.length > 1) {
      const arr = value.filter((item) => item === "NO");
      setValidators(arr);
    } else if (index === -1) setValidators(value);
  };

  return (
    <>
      <Space
        className={styles.addEdlListNewModalCloseIconWrapper}
      >
        <Button
          onClick={() => {
            dispatch(switchAddListSuccess());
            dispatch(setProgress(0));
            close();
          }}
          icon={<IconClose className={styles.addEdlListNewModalCloseIcon} />}
          type="ghost"
          className={styles.addEdlListNewModalCloseButton}
        />
      </Space>

      <div
        className={styles.addEdlListNewModalWrapper}
      >
        <Title className={styles.addEdlListNewModalTitle} level={2}>
          Добавление списка
        </Title>
        <Form
          size="large"
          name="AddList"
          layout="vertical"
          requiredMark={false}
          className={styles.form + ' ' + styles.addEdlListNewModalFormWrapper}
          onFinish={({ listName }) => {
            saveList(listName);
          }}
        >
          {errors && <Alert message={errors} type="error" showIcon />}
          {isLoading && <PreloaderNew />}
          <Row className={styles.addEdlListNewModalFormSwitchWrapper}>
            <Col span={8}>
              <Switch
                checked={active}
                disabled={addSuccess}
                className={styles.addEdlListNewModalFormSwitch}
                checkedChildren=""
                unCheckedChildren=""
                onChange={(evt) => {
                  switchType(file, type, evt);
                  setActive(evt);
                }}
              ></Switch>
              <Text
                className={styles.addEdlListNewModalFormSwitchText}
              >
                {active ? "Активен" : "Неактивен"}
              </Text>
            </Col>
          </Row>
          <Col span={16}>
            {progress !== 0 && <Progress percent={Math.floor(progress)} />}
          </Col>
          {addSuccess && (
            <Alert
              message="Success"
              // description=' Файл загружен и добавлен в очередь на обработку. После успешного завершения процесса, новые записи будут доступны в списке.'
              description={addSuccess}
              type="success"
              showIcon
            ></Alert>
          )}

          <Space direction="horizontal" size={"large"}>
            <Form.Item
              className={styles.input}
              name="listName"
              style={{ width: "292px", color: "#4B4F55", fontWeight: 400 }}
              label={"Название"}
              rules={[
                {
                  required: true,
                  message: "Пожалуйста, введите название списка",
                },
              ]}
            >
              {/* <label>Название</label> */}
              <Input style={{ height: "44px" }} disabled={addSuccess} />
            </Form.Item>

            <Form.Item style={{ width: "292px" }}>
              <Text>Тип</Text>
              <Select
                disabled={addSuccess}
                onChange={(evt) => {
                  setFileError(null);
                  // setFile(null);
                  switchType(file, evt, active);
                  setType(evt);
                }}
                style={{ height: "44px" }}
                value={type}
                options={[
                  { value: "DOMAIN_LIST", label: "DOMAIN" },
                  { value: "IP_ADDRESS_LIST", label: "IP ADDRESS" },
                  { value: "URL_LIST", label: "URL" },
                  { value: "HASH_LIST", label: "HASH" },
                ]}
              />
            </Form.Item>
          </Space>
          <Space direction="horizontal" size={"large"}>
            <label
              className={styles.input_file}
              style={fileError ? { border: "1px solid red" } : {}}
            >
              <span className={styles.input_file_btn}>Выберите файл</span>
              <span className={styles.input_file_text} type="text">
                {file?.name || "Файл не выбран"}
              </span>
              <input
                type="file"
                className={styles.file}
                disabled={addSuccess}
                onClick={() => {
                  setFileError(false);
                  setFile(null);
                }}
                onChange={async (e) => {
                  switchType(e?.target?.files[0], type, active);
                  // const validate = await placeFileContent(e?.target?.files[0]);
                  // if (e?.target?.files[0]?.size <= 104857600 && validate) {
                  //     setFile(e.target.files[0])
                  // }
                  // else setFileError(true)
                }}
                accept=".txt"
              />
            </label>
            <Button
              type="primary"
              size={"large"}
              style={{
                background: "transparent",
                border: "none",
                boxShadow: "none",
                color: "#327FEF",
                padding: 0,
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => handleDownloadEDL()}
            >
              Пример EDL списка <IconDownload />
            </Button>
          </Space>
          <div className={styles.placeholderWrapper}>
            <span
              style={fileError ? { color: "red" } : {}}
              className={styles.placeholderText}
            >
              Допустимый размер файла 50МБ
            </span>
            <span
              style={fileError ? { color: "red" } : {}}
              className={styles.placeholderText}
            >
              Максимальное кол-во строк в одном файле 200000
            </span>
            <span
              style={fileError ? { color: "red" } : {}}
              className={styles.placeholderText}
            >
              Допустимое кол-во строк в лимите {handleFormLabel(type)}
            </span>
            <span className={styles.placeholderText}>
              Поддерживаемые типы файлов: ['txt']
            </span>
          </div>
          <Space
            direction="vertical"
            style={{ width: "300px", marginTop: "10px" }}
            className={styles.validators}
          >
            <Select
              mode="multiple"
              allowClear
              style={{ width: "100%", fontSize: "14px", maxHeight: "30px" }}
              value={validators}
              placeholder="Выбрать Валидатор"
              onChange={handleChange}
              options={
                validatorsType?.map((v) => ({
                  value: v.value,
                  label: v.label,
                  disabled: v.disabled || false,
                })) || []
              }
            />
          </Space>
          {!addSuccess && (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                height: 56,
                marginTop: "24px",
                gap: "24px",
              }}
            >
              <Button
                type="primary"
                onClick={close}
                style={{
                  width: "168px",
                  borderRadius: 4,
                  fontSize: 14,
                  border: "1px solid #CED9EE",
                  background: "transparent",
                  color: " #2E496F",
                }}
              >
                Отменить
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                disabled={isLoading || fileError ? true : false}
                style={{
                  width: "168px",
                  borderRadius: 4,
                  fontSize: 14,
                  background: "#2E496F",
                }}
              >
                Добавить
              </Button>
            </div>
          )}
          {addSuccess && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                height: 56,
                marginTop: "24px",
                gap: "24px",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  dispatch(switchAddListSuccess());
                  dispatch(setProgress(0));
                  close();
                }}
                style={{
                  width: "168px",
                  borderRadius: 4,
                  fontSize: 14,
                  background: "#2E496F",
                }}
              >
                Закрыть
              </Button>
            </div>
          )}
        </Form>
      </div>
    </>
  );
}
export default AddEdlListNew;
