import React from 'react'
import { Row, Col, Button, Select, Radio, Divider } from 'antd';
import { useSelector } from 'react-redux';
import { getSandboxMyAssetsDrop } from '../../store/sandbox/selectors';
import styles from './Sandbox.module.css'

export default function StatsFiltersArea({
    statsDateAfterFilter,
    statsDateBeforeFilter,
    setStatsValue,
    statsPoint,
    setStatsPoint,
    setStatsSourceIn,
    statsValue,
    org,
    clearFilters,
    fetchStatsData,
    setReset


}) {
    const myAssetsDrop = useSelector(getSandboxMyAssetsDrop);


    const selectDropdown = (menu, setStatsPoint, statsPoint, setStatsValue) => {
        return (
            <>
                <Radio.Group
                    onChange={(e) => {
                        if (e.target.value === 'api') {
                            setStatsPoint(e.target.value)
                            setStatsSourceIn('01')
                            setStatsValue('API')
                        }
                        else {
                            setStatsPoint(e.target.value);
                            e.target.value === 'customer' ? setStatsSourceIn('02') : setStatsSourceIn('00,03')
                            setStatsValue(null)
                        }

                    }}
                    value={statsPoint}
                >
                    <div
                        className={styles.statisticFiltersRadioGroup}
                    >
                        <Radio className={styles.statisticFiltersRadioGroupText}  value={'firewall'}>Устройство</Radio>
                        <Radio className={styles.statisticFiltersRadioGroupText}  value={'customer'}>Пользователь</Radio>
                        <Radio className={styles.statisticFiltersRadioGroupText} value={'api'}>API</Radio>

                    </div>
                </Radio.Group>
                {statsPoint !== 'api' && <Divider className={styles.statisticFiltersRadioGroupDivider} />}
                {(statsPoint === 'customer' || statsPoint === 'firewall') && menu}
            </>
        );
    };
    const getSelectArray = (key) => {
        if (key === 'firewall') { return myAssetsDrop }
        if (key === 'customer') { return org.users }
    }
    return (
        <Row gutter={10} className={styles.statisticsFiltersWrapper}>
            <Col span={6}>
                <Select
                    data-testid='statsSelect'
                    showSearch
                    maxTagCount={1}
                    mode={(statsPoint === 'customer' || statsPoint === 'firewall') ? "multiple" : 'single'}
                    allowClear={true}
                    placeholder="Отправитель"
                    className={styles.statisticsFiltersSelector}
                    optionFilterProp={statsPoint === 'customer' ? 'label' : 'value'}
                    dropdownRender={(menu) =>
                        selectDropdown(menu, setStatsPoint, statsPoint, setStatsValue)
                    }
                    value={statsValue || undefined}
                    onChange={(e) => { 
                        setReset('reset')
                        if (e) { setStatsValue(e); } else { setStatsValue(null); setStatsPoint(null) } }}
                    // onDeselect{()=> }
                    onClear={() => { setStatsPoint(null), setStatsValue(null), setStatsSourceIn('') }}
                    options={
                        getSelectArray(statsPoint)?.map((a) => ({
                            value: a.id ? a.id : a.firewall_id,
                            label: a.firewall_id ? a.firewall_id : a.first_name + ' ' + a.last_name,
                        }))
                    }
                />

            </Col>
            <Col span={7} className={styles.statisticsFiltersWrapper}>

                    <Button
                    className={styles.statisticsFiltersButtons}
                        style={{
                            backgroundColor: (statsPoint || statsDateAfterFilter ||
                                statsDateBeforeFilter || (Array.isArray(statsValue) && statsValue?.length !== 0)) ? '#2E496F' : "transparent",
                            color: (statsPoint || statsDateAfterFilter ||
                                statsDateBeforeFilter || (Array.isArray(statsValue) && statsValue?.length !== 0)) ? 'white' : '#2E496F',                         
                        }}
                        disabled={!statsPoint || statsValue?.length === 0}
                        onClick={() => clearFilters()}
                    >
                        Сбросить
                    </Button>


                    <Button
                        className={styles.statisticsFiltersButtons + ' ' + styles.sandboxSubmitBtn}
                        onClick={() => fetchStatsData('reset')}
                    >
                        Обновить
                    </Button>
            </Col>

        </Row>
    )
}