// eslint-disable react-hooks/exhaustive-deps
import React, { useEffect } from "react";
import styles from "./EdlStyles.module.css";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line no-unused-vars
import { patchEdlInstance, switchPatchSuccess } from "../../store/edl";
import {
  getEdlAddLoaders,
  getEdlPatchErrors,
  getEdlSuccessPatch,
} from "../../store/edl/selectors";
import { Alert, Button, Form, Input, Typography } from "antd";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { getUser } from "../../store/auth/selectors";
const { TextArea } = Input;

function PatchEdlLInstanceNew({ close, item, list }) {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const errors = useSelector(getEdlPatchErrors);
  const userData = useSelector(getUser);
  const isLoading = useSelector(getEdlAddLoaders);
  const patchSuccess = useSelector(getEdlSuccessPatch);

  const saveInstance = async (value) => {
    const data = value;
    if (userData?.first_name || userData?.last_name)
      data.updated_by = `${userData?.first_name} ${userData?.last_name}`;
    else data.updated_by = userData?.email;
    dispatch(
      patchEdlInstance({ data: data, idInstance: item.id, idList: list.id })
    );
  };

  useEffect(() => {
    if (item)
      form.setFieldsValue({
        InstName: item.value,
        InstDesc: item.comment,
      });
  }, [item]);

  const handlePlaceHolder = () => {
    let placeholder = "";
    // eslint-disable-next-line default-case
    switch (list.list_type) {
      case "IP_ADDRESS_LIST": {
        placeholder =
          "192.168.1.15, 192.168.20.10/32, 001:db8:123:1::/64, 192.168.20.40-192.168.20.50";
        break;
      }
      case "DOMAIN_LIST": {
        placeholder = "company.com, *.company.com, ^eng.company.com";
        break;
      }
      case "URL_LIST": {
        placeholder = "example.com/*, google.^.com, url.com/cats, 192.168.0.1";
        break;
      }
    }

    return placeholder;
  };

  return (
    <>
      <Title className={styles.modalTitle} level={2}>
        Изменить запись
      </Title>

      <Form
        size="large"
        name="AddList"
        layout="vertical"
        form={form}
        requiredMark={false}
        className={styles.form + ' ' + styles.formWrapper}
        onFinish={({ InstName, InstDesc }) => {
          const data = {};
          if (InstName !== item.value) data.value = InstName;
          if (InstDesc !== item.comment) data.comment = InstDesc;
          saveInstance(data);
        }}
      >
        {errors && <Alert message={errors} type="error" showIcon />}
        {isLoading && <PreloaderNew />}

        {patchSuccess && (
          <Alert
            message="Success"
            description="Запись успешно изменена "
            type="success"
            showIcon
          ></Alert>
        )}

        <Form.Item
          className={styles.input + ' ' + styles.inputWrapper}
          name="InstName"
          label={"Значение записи"}
          rules={[
            { required: true, message: "Пожалуйста, введите значение записи" },
          ]}
        >
          <Input style={{ height: "44px" }} disabled={patchSuccess} />
        </Form.Item>

        <div
          className={styles.placeholderWrapper + ' ' + styles.modalTitle}
        >
          <span className={styles.placeholderText}>{handlePlaceHolder()}</span>
        </div>
        <Form.Item
          className={styles.input + ' ' + styles.inputWrapper}
          name="InstDesc"
          label={"Комментарий"}
        >
          <TextArea
            rows={4}
            placeholder="Максимум 255 символов"
            maxLength={255}
          />
        </Form.Item>

        {!patchSuccess && (
          <div
            className={styles.buttonsAreaWrapper}
          >
            <Button
              type="primary"
              onClick={close}
              className={styles.primaryButton + ' ' + styles.discardButton}
            >
              Отменить
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isLoading}
              className={styles.primaryButton + ' ' + styles.confirmButton}
            >
              Изменить
            </Button>
          </div>
        )}
        {patchSuccess && (
          <div
            className={styles.buttonsAreaWrapper + ' ' + styles.justifyContentCenter}
          >
            <Button
              type="primary"
              onClick={() => {
                dispatch(switchPatchSuccess());
                close();
              }}
              className={styles.primaryButton + ' ' + styles.confirmButton}
            >
              Закрыть
            </Button>
          </div>
        )}
      </Form>
    </>
  );
}
export default PatchEdlLInstanceNew;
