import { Button, Drawer, Space, Table, Typography } from "antd";
import styles from "./OrgArea.module.css";
import React, { useEffect, useState } from "react";
import { IconClose } from "../../icons";
import { useDispatch, useSelector } from "react-redux";
import { getAssetDetail } from "../../store/organisation";
import { getDetailAsset } from "../../store/organisation/selectors";

const { Title, Link, Text } = Typography;

function AssetItemModal({ open, close, item }) {
  const [headerVisible, setHeaderVisible] = useState(false);
  const [bodyRef, setBodyRef] = useState(null);
  const dispatch = useDispatch();
  const data = useSelector(getDetailAsset);

  const onScroll = () => {
    setHeaderVisible(bodyRef.scrollTop > 50);
  };

  useEffect(() => {
    if (bodyRef) {
      bodyRef.addEventListener("scroll", onScroll);
      return () => bodyRef.removeEventListener("scroll", onScroll);
    }
  }, [bodyRef]);

  useEffect(() => {
    if (item !== null) dispatch(getAssetDetail(item));
  }, [item]);
  return (
    <Drawer
      open={open}
      onClose={close}
      width={700}
      // bodyStyle={{ padding: 0 }}
      styles={{ header: { display: "none" } }}
    >
      <div
        ref={(el) => setBodyRef(el?.parentElement)}
        style={{
          height: headerVisible ? 20 : 0,
          transition: "height 250ms",
        }}
      />
      <Space
        className={styles.assetModalHeaderWrapper}
        style={{
          height: headerVisible ? 44 : 64,
          backgroundColor: headerVisible ? "#fff" : "",
          boxShadow: headerVisible
            ? "0px 0px 12px 0px rgba(0, 0, 0, 0.10)"
            : "",
          transition: "height 250ms",
        }}
      >
        <Title
          level={3}
          className={styles.assetModalHeaderTitle}
          style={{
            opacity: headerVisible ? 1 : 0,
          }}
        >
          {data?.title}
        </Title>
        <Button
          onClick={close}
          icon={<IconClose className={styles.iconCloseModal} />}
          type="ghost"
          className={styles.buttonCloseModal}
        />
      </Space>
      <Space
        direction="vertical"
        size={24}
        className={styles.modalSubtitle}
      >
        <Space size={16}>
          <Title level={4} className={styles.modalSubtitleOrgName}>
            {data?.organisation}
          </Title>
          <Link href="#modal_subscriptions" className={styles.modalSubtitleFirstLink}>
            Подписки
          </Link>
          <Link href="#modal_services">Сервисы</Link>
          <Link href="#modal_clouds">Облака</Link>
        </Space>

        <div
          className={styles.modalCard}
        >
          <Text className={styles.grayText}>Серийный номер</Text>
          <Text>{data?.serial_nr}</Text>
          <Text className={styles.grayText}>Страна</Text>
          <Text>{data?.country}</Text>
          <Text className={styles.grayText}>Город</Text>
          <Text>{data?.city}</Text>
          <Text className={styles.grayText}>Платформа</Text>
          <Text>{data?.platform_name}</Text>
          <Text className={styles.grayText}>Тип</Text>
          <Text>{data?.platform_type}</Text>
        </div>

        <div>
          <div
            id="modal_subscriptions"
            className={styles.modalTablesWrapper}
          />
          <Title level={3}>Подписки</Title>
          <Table
            columns={columnsSubscriptions}
            dataSource={data?.subscriptions}
            className={styles.tableModal}
            size="small"
            pagination={false}
            rowKey={(obj) => obj.slug + obj.description}
          />
        </div>

        <div>
          <div id="modal_services" className={styles.modalTablesWrapper} />
          <Title level={3}>Сервисы</Title>
          <Table
            columns={columnsServices}
            dataSource={data?.service}
            className={styles.tableModal}
            size="small"
            pagination={false}
            rowKey={(obj) => obj.slug + obj.description}
          />
        </div>

        <div>
          <div id="modal_clouds" className={styles.modalTablesWrapper} />
          <Title level={3}>Облака</Title>
          <Table
            columns={columnsClouds}
            dataSource={data?.clouds}
            className={styles.tableModal}
            size="small"
            pagination={false}
            rowKey={(obj) => obj.slug + obj.address}
          />
        </div>
      </Space>
    </Drawer>
  );
}

const columnsSubscriptions = [
  createColumn("Статус", "status", renderStatus),
  createColumn("Slug", "slug"),
  createColumn("Осталось", "time_left_verbose"),
  createColumn("Дата окончания", "date_end"),
  createColumn("Описание", "description"),
];

const columnsServices = [
  createColumn("Статус", "status", renderStatus),
  createColumn("Slug", "slug"),
  createColumn("Осталось", "time_left_verbose"),
  createColumn("Дата окончания", "date_end"),
  createColumn("Описание", "description"),
  createColumn("RMA", "rma", (_, { rma }) => (
    <span style={rma === true ? { color: "blue" } : { color: "black" }}>
      {rma === true ? "Да" : "Нет"}
    </span>
  )),
];

const columnsClouds = [
  createColumn("Адресс", "address"),
  createColumn(
    "Slug",
    "slug"
    // (_, { slug }) =>
    // 	<div style={{ whiteSpace: "nowrap" }}>
    // 		<Dot color={{
    // 			"green": "#219653",
    // 			"yellow": "#FFBC36",
    // 			"red": "#EB5757",
    // 		}[slug.color]} />
    // 		<Space direction="vertical" size={0}>
    // 			<Text>{slug.time}</Text>
    // 			<Text>{slug.text}</Text>
    // 		</Space>
    // 	</div>
  ),
  createColumn("Тип обновления", "update_type"),
  createColumn("Описание", "description"),
];

function renderStatus(status) {
  return (
    <div
      style={{
        backgroundColor: status !== "ACTIVE" ? "#EB5757" : "#E0F6EA",
        color: status !== "ACTIVE" ? "#fff" : "#219653",
        display: "inline",
        padding: "4px 8px",
        borderRadius: 2,
        textTransform: "uppercase",
      }}
    >
      {status}
    </div>
  );
}

function createColumn(title, key, render) {
  return { title, key, dataIndex: key, render };
}

export default AssetItemModal;
