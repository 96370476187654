import React from 'react'
import { useState, useEffect } from "react";
import { Space, Row, Col } from "antd";
import { useDispatch } from "react-redux";
import { DistributionsServerDetails } from "../DistributionsServerDetails/DistributionsServerDetails";
import { DistributionsAgentDetails } from "../DistributionsAgentDetails/DistributionsAgentDetails";
import { DistributionsDocTable } from "./DistributionsDocTable";
import { DistributionsAgentTable } from "./DistributionsAgentTable";
import { DistributionsServerTable } from "./DistributionsServerTable";
import { DistributionsCompatibilityTable } from "./DistributionsCompatibilityTable";
import { resetData, getPimDocDownload} from "../../store/distributions";
import { getExtension } from "./utils";
import styles from "./Distributions.module.css";

function DistributionsList() {
  const dispatch = useDispatch();
  const [isModalViewOpenServer, setIsModalViewOpenServer] = useState(false);
  const [isModalViewOpenAgent, setIsModalViewOpenAgent] = useState(false);

  const downloadFile = async (file) => {
    dispatch(
      getPimDocDownload({
        docId: file.id,
        name: file.file_name,
        typeFile: getExtension(file.file_name),
      })
    );
  };

  useEffect(() => {
    dispatch(resetData());
  }, []);

  return (
    <>
      <Space direction="vertical" size={20} className={styles.distributionAreaAndListSpaceTopLevel}>
        <Row gutter={16}>
          <Col span={12}>
            <DistributionsServerTable
              createColumn={createColumn}
              setIsModalViewOpenServer={setIsModalViewOpenServer}
            />
          </Col>
          <Col span={12}>
            <DistributionsAgentTable
              createColumn={createColumn}
              setIsModalViewOpenAgent={setIsModalViewOpenAgent}
            />
          </Col>
        </Row>
        <DistributionsCompatibilityTable createColumn={createColumn} />
        <DistributionsDocTable
          downloadFile={downloadFile}
          createColumn={createColumn}
        />
      </Space>
      {isModalViewOpenServer && (
        <DistributionsServerDetails
          open={isModalViewOpenServer}
          close={() => setIsModalViewOpenServer(false)}
          selectedDistributions={isModalViewOpenServer}
        />
      )}
      {isModalViewOpenAgent && (
        <DistributionsAgentDetails
          open={isModalViewOpenAgent}
          close={() => setIsModalViewOpenAgent(false)}
          selectedDistributions={isModalViewOpenAgent}
        />
      )}
    </>
  );
}

function createColumn(title, key, render, width, align) {
  return { title, key, dataIndex: key, render, width, align };
}

export default DistributionsList;
