import React from "react";
import { Button, Modal, Typography } from "antd";
import { deleteEdlInstance, switchDeleteSuccess } from "../../store/edl";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../store/auth/selectors";
import { getEdlSuccessDelete } from "../../store/edl/selectors";
import styles from "./EdlStyles.module.css";

function ModalConfirmInctance({ open, close, item, list }) {
  const dispatch = useDispatch();
  const isSuccessDelete = useSelector(getEdlSuccessDelete);
  const user = useSelector(getUser);

  const handleActivateDelete = () => {
    dispatch(
      deleteEdlInstance({
        idList: list,
        idInstance: item.id,
        userUpdated: user,
      })
    );
  };
  const handleClose = () => {
    dispatch(switchDeleteSuccess());
    close();
  };

  return (
    <Modal open={open} closable={false} footer={null} centered>
      <div
        className={styles.modalComfirmWrapper}
      >
        {!isSuccessDelete && (
          <Typography.Title level={3}>
            {"Вы уверены, что хотите удалить запись?"}
          </Typography.Title>
        )}
        {isSuccessDelete && (
          <Typography.Title level={3}>Запись успешно удалена!</Typography.Title>
        )}
        {!isSuccessDelete && (
          <div
            className={styles.confirmBtnWrapper}
          >
            <Button onClick={close} className={styles.modalComfirmButtons} htmlType="reset">
              Отмена
            </Button>
            <Button
              className={styles.modalComfirmButtons + ' ' + styles.primarylComnfirmBtn}
              type="primary"
              onClick={() => handleActivateDelete()}
            >
              {"Удалить"}
            </Button>
          </div>
        )}
        {isSuccessDelete && (
          <Button
          className={styles.modalComfirmButtons + ' ' + styles.primarylComnfirmBtn}
            type="primary"
            onClick={() => {
              handleClose();
            }}
          >
            {"Закрыть"}
          </Button>
        )}
      </div>
    </Modal>
  );
}

export default ModalConfirmInctance;
