import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getSourceIn, parseIfUserName } from "./utils/utils";
import {
  Button,
  Space,
  Table,
  Badge,
  message,
  Typography,
  Spin
} from "antd";
import { useSelector } from "react-redux";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { getSandboxRequestsTableData, getSandboxRequestDetails, getSandboxRequestsTableTotal } from "../../store/sandbox";
import { IconCopy } from "../../icons";
import DetailsDrawer from "./DetailsDrawer";
import {
  getSandboxReqTableData,
  getSandboxMyAssetsDrop,
  getSandboxTableLoader,
  getSandboxLTotalForTable
} from "../../store/sandbox/selectors";
import styles from './Sandbox.module.css'
import TableFiltersArea from "./TableFiltersArea";
import { EyeOutlined } from "@ant-design/icons";
import { copyBufferTextTable } from "./utils";

import { getCollapsed } from "../../store/sidebar/selectors";

function SandboxList({
  permissions,
  org,
  activeKey
}) {
  const dispatch = useDispatch();
  const [resetInput, setResetInput] = useState(false)
  const assetsData = useSelector(getSandboxReqTableData);
  const isLoader = useSelector(getSandboxTableLoader)
  const myAssetsDrop = useSelector(getSandboxMyAssetsDrop);
  const tableTotal = useSelector(getSandboxLTotalForTable)
  const [createdAtOrdering, setCreatedAtOrdering] = useState('descend')
  const [finishedCheckAtOrdering, setFinishedCheckAtOrdering] = useState(null)
  const [refreshing, setRefreshing] = useState(false);
  const [sourceIn, setSourceIn] = useState('');
  const [order, setOrder] = useState('-created_at');
  const [search, setSearch] = useState({value: '', type_search: 'sha256'});

  const [verdictFilter, setVerdictFilter] = useState(null);
  const [source, setSource] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [fileTypeFilter, setFileTypeFilter] = useState(null);
  const [serial, setSerial] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false)
  const [point, setPoint] = useState(null);
  const [value, setValue] = useState(null);
  const [dateAfterFilter, setDateAfterFilter] = useState(null);
  const [dateBeforeFilter, setDateBeforeFilter] = useState(null);
  const [requestUid, setRequestUid] = useState(null)
  const { Text } = Typography;
  const [messageApi, contextHolder] = message.useMessage()
  const isSidebarCollapsed = useSelector(getCollapsed)


  const clearFilters = () => {
    setStatusFilter(null)
    setSearch({value: '', type_search: 'sha256'});
    setFileTypeFilter(null);
    setVerdictFilter(null);
    setPoint(null);
    setValue(null);
    setSourceIn('');
    setResetInput(!resetInput)
    setDateAfterFilter(null);
    setDateBeforeFilter(null);
  };



  const fetchData = () => {
    let filters;
    //if (statusFilter || verdictFilter || search?.value || fileTypeFilter || value || dateAfterFilter || dateBeforeFilter || refreshing || currentPage || order) {
      filters = {
        status__in: statusFilter?.join(','),
        order_by: order,
        source__in: getSourceIn(value, sourceIn),
        user_id__in: sourceIn === '00,03' || sourceIn === '01' ? null : (Array.isArray(value) ? value?.join(',') : value),
        firewall_id: sourceIn === '02' || sourceIn === '01' ? null : (Array.isArray(value) ? value?.join(',') : value),
        file_type__in: fileTypeFilter?.join(','),
        verdict__in: verdictFilter?.join(','),
        created_at__gte: dateAfterFilter ? formatDate(dateAfterFilter) : null,
        created_at__lte: dateBeforeFilter ? formatDate(dateBeforeFilter) : null,
        page: refreshing ? 1 : currentPage,
      };
      if (search?.value)  {
        if (search.type_search==='sha256')filters.sha256 = search?.value
        else filters.search = search?.value
    }
    Object.keys(filters).forEach(key => {
      if (filters[key] === null || filters[key] === undefined || filters[key] == []) {
        delete filters[key]
      }
    })
    // if (filters.source__in === '01' || (filters.source__in === '00,03' && value !== null)
    //   || (filters.source__in === '02' && value !== null
    //     // && value?.length>0
    //   ) ) {
      if (permissions?.full_access || permissions?.read_access) {
        dispatch(
          getSandboxRequestsTableData({ filters: filters})
        );
        delete filters?.page;
        delete filters?.order_by;
        dispatch(
          getSandboxRequestsTableTotal({ filters: filters})
        );
        setRefreshing(false)
      }
    //}

  //}
  };

  // const refresh = () => {
  //   if (permissions?.full_access || permissions?.read_access) {
  //    fetchData()
  //   }
  // }

  function formatDate(input) {
    return input.split('.')[0];
  }

  useEffect(() => {
    if (requestUid !== null) {
      dispatch(getSandboxRequestDetails(requestUid));
    }
  }, [requestUid])

  useEffect(() => {
    if ((permissions?.full_access || permissions?.read_access) && activeKey === 2) {
      if ((!value || value?.length===0) &&sourceIn) return
      const timerRefresh = setTimeout(fetchData, 600);
      return () => {
        clearTimeout(timerRefresh);
      };
    }

  }, [activeKey, search.value, verdictFilter, fileTypeFilter, value, permissions, statusFilter, dateAfterFilter, dateBeforeFilter, currentPage, order, refreshing]);

  function renderMultilineTitle(title) {
    return (
      <Space size={0} direction="vertical">
        <span>{title}</span>
      </Space>
    );
  }

  const viewStatus = (status) => {
    let color;
    if (status === 'accepted' || status === 'pending' || status === 'stage_02' || status === 'stage_01' || status === 'stage_03') {
      color = "#FFF9EC";
    }
    else if (status === 'finished' || status === 'succeeded') {
      color = '#EAF0FF';
    }
    else if (status === 'error' || status === 'timeout' || status === 'failed') {
      color = "#FFECEC";
    }
    else {
      color = "grey";
    }
    return color;
  };

  const viewStatusTextColor = (status) => {
    let color;
    let text;
    if (status === 'accepted' || status === 'pending' || status === 'stage_02' || status === 'stage_01' || status === 'stage_03') {
      color = "#E39800";
      text = 'проверяется';
    }
    else if (status === 'finished' || status === 'succeeded') {
      color = '#327FEF';
      text = 'проверен';
    }
    else if (status === 'error' || status === 'timeout' || status === 'failed') {
      color = "#EB5757";
      text = 'ошибка';
    }
    else {
      color = "grey";
    }
    return { color: color, text: text };
  };

  const viewVerdict = (verdict) => {
    let color;
    switch (verdict) {
      case "Malware":
        color = "#FFECEC";
        break;
      case "Clean":
        color = "#E0F6EA";
        break;
      case "Grayware":
        color = '#FFF9EC';
        break;
      default:
        color = "#f8f8ff";
    }
    return color;
  };

  const viewVerdictTextColor = (verdict) => {
    let color;
    switch (verdict) {
      case "Malware":
        color = "#EB5757";
        break;
      case "Clean":
        color = "#219653";
        break;
      case "Grayware":
        color = '#E39800';
        break;
      default:
        color = "grey";
    }
    return color;
  };

  const changeDate = (date) => {
    if (date) {
      const formatDate = date?.split("T");
      const reverseDate =
        formatDate[0].split("-").reverse().join(".") +
        " " +
        formatDate[1].split(".")[0];
      return reverseDate;
    } else return null;
  };
  const badgeContent = (text, row) => {
    if (!text && (row['status'] === 'error' || row['status'] === 'timeout' || row['status'] === 'failed')) {
      return 'unknown'.toUpperCase();
    } else if (text !== 'Clean') {
      return text?.toUpperCase();
    } else {
      return 'Benign'.toUpperCase();
    }
  };
  const columns = [
    createColumn(
      renderMultilineTitle(<div>Начало проверки (UTC)</div>, "Ordered Desc"),
      "created_at", '11%',
      (text) => {
        return (
          <div className={styles.sandboxListDateTimeWrapper}>
            <Text>{changeDate(text).split(' ')[0]}</Text>
            <Text className={styles.sandboxListTime}>{changeDate(text).split(' ')[1]}</Text>
          </div>
        )
      },
      () => {
        return 0;
      },
      createdAtOrdering
    ),
    createColumn(<div>Конец проверки (UTC)</div>, "finished_check_at", '11%', (text) => {
      return (
        <div className={styles.sandboxListDateTimeWrapper}>
          <Text>{changeDate(text)?.split(' ')[0]}</Text>
          <Text className={styles.sandboxListTime}>{changeDate(text)?.split(' ')[1]}</Text>
        </div>
      )
    },
      () => {
        return 0;
      },
      finishedCheckAtOrdering
    ),
    createColumn(<div>Отправитель</div>, "source_id", '10%', (text) => (
      <div>{parseIfUserName(org, text)}</div>)
    ),
    createColumn(<div>Статус</div>, "status", '8%', (text) => (
      <Badge
        className={styles.sandboxListStatusBadge}
        style={{
          color: viewStatusTextColor(text).color
        }} count={viewStatusTextColor(text).text?.toUpperCase()} showZero color={viewStatus(text)} />
    )),
    createColumn(<div>sha256</div>, "checked_file_sha256", '8%', (text) => (
      <div className={styles.sandboxListSha}>
        ...{text?.slice(-10)}
        <Button
          size="small"
          type="text"
          icon={<IconCopy className={styles.copyIcon} />}
          onClick={() => {
            copyBufferTextTable(text, messageApi);
          }}
        />
      </div>
    )),
    createColumn(<div>Вердикт</div>, "verdict", '7%', (text, row) => (
      <Badge
        className={styles.sandboxListStatusBadge}
        style={{
          color: viewVerdictTextColor(text)
        }} count={badgeContent(text, row)} showZero color={viewVerdict(text)} />
    )),
    createColumn(<div>Имя файла</div>, "file_name", '30%', (text) => {
      return (
        <div className={styles.sandboxListFileName}>
          {text}
        </div>)
    }),
    createColumn(<div>Тип</div>, "file_type", '3%'),
    createColumn(<div>Детали</div>, "uid", '7%', (uid) => (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <EyeOutlined className={styles.sandboxListOpenDetailsIcon} onClick={() => { setOpenDetailsDrawer(true); setRequestUid(uid) }} />
    </div>)),
  ];

  const handlePageChange = (pagination) => {
    setCurrentPage(pagination.current)
  }

  const customLoader = (
    <Spin className={styles.tableLoader} style={{left :isSidebarCollapsed? '50%' : '60%'}} indicator={<PreloaderNew />} />
  );

  return (
    <Space
      direction="vertical"
      size={20}
      className={styles.sandboxListAreaWrapper}>
      {contextHolder}
      <DetailsDrawer openDetailsDrawer={openDetailsDrawer} setOpenDetailsDrawer={setOpenDetailsDrawer} requestUid={requestUid} setRequestUid={setRequestUid} />
      <TableFiltersArea
        search={search}
        //refresh={refresh}
        sourceIn={sourceIn}
        setRefreshing={setRefreshing}
        fetchData={fetchData}
        setSearch={setSearch}
        resetInput={resetInput}
        setResetInput={setResetInput}
        assetsData={assetsData}
        serial={serial}
        setSerial={setSerial}
        myAssetsDrop={myAssetsDrop}
        setSourceIn={setSourceIn}
        setSource={setSource}
        source={source}
        setFileTypeFilter={setFileTypeFilter}
        fileTypeFilter={fileTypeFilter}
        point={point}
        setPoint={setPoint}
        value={value}
        setValue={setValue}
        clearFilters={clearFilters}
        setCurrentPage={setCurrentPage}
        verdictFilter={verdictFilter}
        setVerdictFilter={setVerdictFilter}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
        org={org}
        refreshing={refreshing}
        setDateBeforeFilter={setDateBeforeFilter}
        dateBeforeFilter={dateBeforeFilter}
        dateAfterFilter={dateAfterFilter}
        setDateAfterFilter={setDateAfterFilter}
        isLoader={isLoader}
      />


      <div className={styles.sandboxListTotalListItemsCountWrapper}>
        <Text className={styles.sandboxListTotalListItemsCountText + ' ' + styles.title}>Всего</Text>
        <Text className={styles.sandboxListTotalListItemsCountText}>{tableTotal}</Text>
      </div>
      {<Table
        data-testid='requestsTable'
        loading={{ indicator: customLoader, spinning: isLoader }}
        columns={columns}
        className={styles.table}
        rowKey={(obj) => obj.uid}
        size="small"
        locale={{
          triggerDesc: "сортировать по убыванию",
          triggerAsc: "сортировать по возрастанию",
          cancelSort: "отменить сортировку"
        }}
        pagination={assetsData? {
          current: currentPage,
          onChange: handlePageChange,
          total: tableTotal,
          pageSize: 25,
          showSizeChanger: false
        }: false}
        onHeaderRow={() => {
          return {
            onClick: (e) => {
              if (e.target.innerText === "НАЧАЛО ПРОВЕРКИ (UTC)") {
                setFinishedCheckAtOrdering(null);
                switch (createdAtOrdering) {
                  case "ascend":
                    setCreatedAtOrdering("descend");
                    setOrder('-created_at')
                    break;
                  case "descend":
                    setCreatedAtOrdering(null);
                    setOrder(null)
                    break;
                  default:
                    setCreatedAtOrdering("ascend");
                    setOrder('created_at')
                }
              }
              if (e.target.innerText === "КОНЕЦ ПРОВЕРКИ (UTC)") {
                setCreatedAtOrdering(null);
                switch (finishedCheckAtOrdering) {
                  case "ascend":
                    setFinishedCheckAtOrdering("descend");
                    setOrder('-finished_check_at')
                    break;
                  case "descend":
                    setFinishedCheckAtOrdering(null);
                    setOrder(null)
                    break;
                  default:
                    setFinishedCheckAtOrdering("ascend");
                    setOrder('finished_check_at')
                }
              }
            },
          };
        }}
        onChange={handlePageChange}
        style={{ overflowX: "auto" }}
        dataSource={assetsData?.items}
      />}
    </Space >
  );
}

function createColumn(title, key, width, render, sorter, sortOrder) {
  return { title, key, dataIndex: key, width, render, sorter, sortOrder };
}

export default SandboxList;
