import React from 'react'
import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Typography,
  message,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getCheckUrl,
  resetUpdUrlData,
  resetUrlData,
} from "../../store/urlfilter";
import { getUrlCheckErrors, getUrlData, getUrlLoaders } from "../../store/urlfilter/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import styles from "./UrlFilterNew.module.css";
import UrlItemModal from "./UrlItemModal";
const { Text } = Typography;
const styleTextGray = { color: "#7A7D86" };

function CheckUrlNew() {
  const [messageApi] = message.useMessage();
  const [inputUrl, setInputUrl] = useState("");
  const dispatch = useDispatch();
  const errors = useSelector(getUrlCheckErrors);
  const isLoader = useSelector(getUrlLoaders);
  const urlData = useSelector(getUrlData);
  const [openModal, setOpenModal] = useState(false);

  const getRandomId = () => {
    let randomId;

    const r = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;

    const rs = (len) =>
      new Array(len)
        .fill(0)
        .map(() => String.fromCharCode(r(97, 122)))
        .join("");
    randomId = rs(100);
    return randomId;
  };

  message.config({ duration: 5 });

  useEffect(() => {
    if (!isLoader && inputUrl && errors) messageApi.error(errors);
  }, [errors, isLoader]);
  return (
    <Space direction="vertical" size={20} className={styles.wrapper}>
      {
        openModal && (
          <UrlItemModal
            open={openModal}
            url={inputUrl}
            close={() => setOpenModal(false)}
            mask={false}
            data={urlData?.kfeed_results[0]}
          />
        )
        // item={modalItem}
      }
   {errors && <Alert message={errors} type="error" showIcon />}
      <Form
        layout="inline"
        className={styles.urlCheckForm}
        requiredMark={false}
        onFinish={({ url }) => {
          // setInputUrl(url);
          dispatch(getCheckUrl(url.trim()));
          setInputUrl(url.trim());
          dispatch(resetUrlData());
          dispatch(resetUpdUrlData());
        }}
      >
        {isLoader && <PreloaderNew />}
      
        <Form.Item
          name="url"
          style={{ flexGrow: 1, marginInlineEnd: 20 }}
          rules={[{ required: true, message: "Пожалуйста, введите URL!" }]}
          className={styles.inputUrl}
        
        >
          <Input
            value={inputUrl}
            name="url"
            placeholder="Введите URL"
            className={styles.urlSearch}
          />
        </Form.Item>
        <Form.Item style={{ flexBasis: 200, flexShrink: 1 }}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles.urlCheckSubmitButton}
            disabled={isLoader ? true : false}
          >
            Проверить
          </Button>
        </Form.Item>
      </Form>

      {urlData && (
        <>
          <Card>
            <Row gutter={40} justify="space-around">
              <Col>
                <Space direction="vertical">
                  <Text style={styleTextGray}>URL</Text>
                  <Text>{urlData?.url}</Text>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical">
                  <Text style={styleTextGray}>Обновлено</Text>
                  <Text>{urlData?.updated_at}</Text>
                </Space>
              </Col>
              <Col>
                <Space direction="vertical">
                  <Text style={styleTextGray}>Категории</Text>
                  <Text>{urlData?.categories?.length}</Text>
                </Space>
              </Col>
            </Row>
          </Card>

          <Table
            columns={columns}
            rowKey={(obj) => obj.cat_id}
            size="small"
            pagination={false}
            className={styles.apiAndUrlCheckTable}
            dataSource={urlData?.categories}
          />
          <hr />
          <Card>
            <div
              className={styles.checkUrlCard}
            >
              <span className={styles.checkUrlCardTitle}>
                KFEED RESULTS ( дата:{" "}
                {urlData?.kfeed_results[0]?.receipted_at || "нет данных"})
              </span>
              {urlData?.kfeed_results[0] && <Button
                onClick={() => setOpenModal(true)}
                type="primary"
                className={styles.checkUrlDetailsButton}
              >
                Детали
              </Button>}
            </div>
          </Card>
          {urlData?.kfeed_results[0]?.categories.length > 0 ? (
            <Table
              columns={columns}
              rowKey={() => getRandomId()}
              size="small"
              pagination={false}
              className={styles.apiAndUrlCheckTable}
              dataSource={urlData?.kfeed_results[0]?.categories}
            />
          ) : (
            <div className={styles.kasperskyAlert}>
              <Text>В текущей базе угроз Kaspersky запись не найдена</Text>
            </div>
          )}
        </>
      )}
    </Space>
  );
}

const columns = [
  createColumn("id", "cat_id"),
  createColumn("Имя", "name"),
  createColumn("Описание", "description"),
];

function createColumn(title, key, render, width) {
  return { title, key, dataIndex: key, render, width };
}

export default CheckUrlNew;
