import React from "react";
import { Descriptions, Space, Table, Typography } from "antd";
import styles from './ThreatVaultModal.module.css'
const { Text } = Typography;

function ThreatVaultCardShaNew({ data,  setModalItem }) {
  /* for table */
const columns = [
  createColumn(
    "Signature",
    "signature",
    (_, row) => (
      <Space size={2} direction="vertical" onClick={()=> setModalItem(row)}>
        <Text underline>{row.name || "Нет данных"}</Text>
        <Text>{"Unique Threat ID: " + row.id}</Text>
      </Space>
    ),
    { cursor: "pointer" },
    (row) => setModalItem(row)
  ),
  createColumn("Severity", "severity"),
  createColumn("CVE", "cve", (_, { cve }) =>
    cve ? (
      <Space size={2} direction="vertical">
        {cve?.map((item, i) => (
          <Text key={item + i}>{item}</Text>
        ))}
      </Space>
    ) : (
      <Text>Нет данных</Text>
    )
  ),
  createColumn("sha256 hashes", "related_sha256_hashes", (_, { related_sha256_hashes }) =>
  related_sha256_hashes ? (
      <Space size={2} direction="vertical">
        {related_sha256_hashes?.map((item) => (
          <Text key={item}>{item}</Text>
        ))}
      </Space>
    ) : (
      <Text>Нет данных</Text>
    )
  ),
  createColumn("Subtype", "subtype"),
  createColumn("First Release", "ori_release_version", (_, { ori_release_version, ori_release_time }) =>
    RenderColumnVersionTime(ori_release_version, ori_release_time)
  ),
  createColumn("Last Update", "latest_release_version", (_, { latest_release_version, latest_release_time }) =>
    RenderColumnVersionTime(latest_release_version,latest_release_time )
  ),
];
  return (
    <>
      <Descriptions bordered size="small" column={1}>
        {GetItem("File Type", data[0]?.filetype)}
        {GetItem("sha256", data[0]?.sha256)}
        {GetItem("sha1", data[0]?.sha1)}
        {GetItem("md5", data[0]?.md5)}
        {GetItem("Size", data[0]?.size)}
        {GetItem("Family", data[0]?.family)}
        {GetItem("Create time", data[0]?.create_time)}
        {GetItem("Wildware verdict", data[0]?.wildfire_verdict)}
        {GetItem("Type", data[0]?.type)}
      </Descriptions>

      <Table
        columns={columns}
        size="small"
        // pagination={{ position: ["bottomCenter"] }}
        pagination={false}
        className={styles.threatVaultCardShaTable}
        dataSource={data[0]?.signatures}
      />
    </>
  );
}

/* for descriptions */
function GetItem(label, data) {
  return (
    <Descriptions.Item
      label={label}
      labelStyle={{
        fontWeight: "bold",
        width: "250px",
        color: "black",
      }}
    >
      {data || "Нет данных"}
    </Descriptions.Item>
  );
}



function createColumn(title, key, render, style, onClick) {
  return {
    title,
    key,
    dataIndex: key,
    render,
    onCell: (record, rowIndex) => ({
      onClick: (e) => onClick?.(record, rowIndex, e),
      style,
    }),
  };
}

function RenderColumnVersionTime(version, time ) {
  return version ? (
    <Text>
      {version} ({time})
    </Text>
  ) : (
    <Text>Нет данных</Text>
  );
}

export default ThreatVaultCardShaNew;
