import React from "react";
import { Drawer, Space, Typography } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import styles from './DistributionsAgentDetails.module.css'
import {
  getDisDetailLoader,
  getDistributionsAgentDetails,
} from "../../store/distributions/selectors";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";

export const DistributionsAgentDetails = ({ open, close }) => {
  const loader = useSelector(getDisDetailLoader);
  const dataDetail = useSelector(getDistributionsAgentDetails);

  function parseReleaseNotes(releaseNotes) {
    const entries = releaseNotes?.trim().split("*");
    return (
      <div className={styles.releaseNotesWrapper}>
        {entries
          ?.filter((entry) => entry?.trim() !== "")
          ?.map((entry, index) => (
            <Space
              className={styles.releaseNotes}
              key={entry + index}
            >
              {entry}
            </Space>
          ))}
      </div>
    );
  }

  return (
    <>
      <Drawer
        styles={{
          body: { padding: "48px" },
        }}
        open={open}
        onClose={() => close()}
        width={650}
      >
        <div
          className={styles.detailsTitleAreaWrapper}
        >
          <Space
            className={styles.detailsTitleArea}
          >
            <Typography.Title level={2} className={styles.detailsTitle}>
              {dataDetail?.major_version? 'v' + dataDetail?.major_version : ''}
            </Typography.Title>
            {dataDetail?.release_date && (
              <Space className={styles.releaseDateArea}>
                <CalendarOutlined className={styles.releaseDateIcon} />
                <Typography.Title
                  level={2}
                  className={styles.detailsTitle}
                >
                  {dataDetail?.release_date}
                </Typography.Title>
              </Space>
            )}
          </Space>
          <Space
            className={styles.detailsLinksArea}
          >
            <a href="#release_notes">Описание релиза</a>
            {dataDetail?.known_issues && (
              <a href="#known_issues">Известные проблемы</a>
            )}
          </Space>
        </div>
        <div className={styles.wholeWidth}>
          {loader && <PreloaderNew />}
          <Typography.Title level={5} id="release_notes">
            Описание релиза
          </Typography.Title>
          {parseReleaseNotes(dataDetail?.release_notes) || "Описания нет"}
          {dataDetail?.known_issues && (
            <>
              <Typography.Title
                level={5}
                id="known_issues"
                className={styles.marginTop}
              >
                Известные проблемы
              </Typography.Title>
              {parseReleaseNotes(dataDetail?.known_issues) || "Данных нет"}
            </>
          )}
        </div>
      </Drawer>
    </>
  );
};
