import React, { useEffect, useState } from "react";
import styles from "./EdlStyles.module.css";
import { Button, ConfigProvider, Drawer, Space, Table, Tabs, } from "antd";
import { IconClose, IconReload } from "../../icons";
import {Typography} from "antd";
import StatusTag from "./StatusTag";
import { useDispatch, useSelector } from "react-redux";
import { getDateTaskDetails, getDetailsLoaders } from "../../store/edl/selectors";
import { getTaskDetails } from "../../store/edl";
import PreloaderNew from "../UI/PreloaderNew/PreloaderNew";
import { numberWithSpaces } from "../../utils/numberWithSpaces";

function ModalTaskList({ itemId, close }) {
	const [selectedTab, setSelectedTab] = useState(tabs[0].key);

	const dispatch = useDispatch();
	const dateTaskDetails = useSelector(getDateTaskDetails)
	const isLoadersDetails = useSelector(getDetailsLoaders)

	const fetchData = () => {
		dispatch(getTaskDetails(itemId));
	};

	useEffect(() => {
		fetchData()
		// if (visible) {
		//     const interval = setInterval(fetchData, 5000); // Обновление каждые 5 секунд
		//     return () => clearInterval(interval); // Очистка интервала при размонтировании или закрытии окна
		// }
	}, [itemId]);

	return (
		<Drawer
			open={itemId >= 0}
			onClose={close}
			width={700}
			
			styles={{ header: {display: "none"}}}
		>
			<Space className={styles.modalWarningsWrapper}>
				<Button
					onClick={close}
					icon={<IconClose className={styles.addEdlListNewModalCloseIcon}/>}
					type="ghost"
					className={styles.closeWarningsButton}
				/>
			</Space>
			<Space
				direction="vertical"
				size={24}
				className={styles.modalWarningsTitleWrapper}
			>
				{isLoadersDetails && <PreloaderNew />}
				<Typography.Title level={2} >{dateTaskDetails?.name}</Typography.Title>
				<ConfigProvider theme={{
					components: {
						Tabs: {
							itemColor: "#8090B8",
							itemHoverColor: "#333333",
							itemSelectedColor: "#333333",
							inkBarColor: "#333333",
						}
					}
				}}>
					<div className={styles.tasksModalGrid}>
						<Tabs
							items={tabs}
							activeKey={selectedTab}
							onChange={key => setSelectedTab(key)}
						/>
						<Button icon={<IconReload />} onClick={() => fetchData()}>Обновить</Button>
					</div>
				</ConfigProvider>

				{selectedTab === "details" &&
					<div className={styles.descriptions}>
						<div>ID</div>
						<div>{dateTaskDetails?.task_id}</div>
						<div>Тип</div>
						<div>{dateTaskDetails?.task_type}</div>
						<div>Создан</div>
						<div>{dateTaskDetails?.created_by}</div>
						<div>Валидных значений</div>
						<div>{numberWithSpaces(dateTaskDetails?.result?.valid_values_cnt)}</div>
						<div>Удалено дубликатов</div>
						<div>{numberWithSpaces(dateTaskDetails?.result?.duplicates_deleted_cnt)}</div>
						<div>Ошибки</div>
						<div>{numberWithSpaces(dateTaskDetails?.result?.invalid_values_cnt)}</div>
						<div>Дата создания</div>
						<Space>
							<span>{dateTaskDetails?.date_created}</span>
						</Space>
						<div>Дата изменения</div>
						<Space>
							<span>{dateTaskDetails?.date_modified}</span>

						</Space>
						<div>Статус</div>
						<div><StatusTag status={dateTaskDetails?.status} /></div>
					</div>
				}

				{selectedTab == "errors" &&
					<div>
						<Typography.Title level={5}>Всего ошибок: {dateTaskDetails?.result?.invalid_values_cnt > 100 ? `${dateTaskDetails?.result?.invalid_values_cnt} (показаны первые 100 найденных ошибок)` : dateTaskDetails?.result?.invalid_values_cnt}</Typography.Title>
						<Table
							columns={columnsErros}
							dataSource={dateTaskDetails?.result?.errors}
							className={styles.tableModal}
							rowKey={obj => obj.value + obj.line_nr}
							size="small"
							pagination={{
								position: "bottomRight",
								defaultPageSize: 10,
								total: dateTaskDetails?.result?.errors?.length,
							}}

						/>
					</div>
				}

				{selectedTab == "dubles" &&
					<div>
						<Typography.Title level={5}>Всего дублей: {dateTaskDetails?.result?.duplicates_deleted_cnt > 100 ? `${dateTaskDetails?.result?.duplicates_deleted_cnt} (показаны первые 100 найденных дублей)` : dateTaskDetails?.result?.duplicates_deleted_cnt}</Typography.Title>
						<Table
							rowKey={obj => obj.value + obj.line_nr}
							columns={columnsDubles}
							dataSource={dateTaskDetails?.result?.duplicates}
							className={styles.tableModal}
							size="small"
							pagination={{
								position: "bottomRight",
								defaultPageSize: 10,
								total: dateTaskDetails?.result?.duplicates?.length,
							}}
						/>
					</div>
				}
			</Space>
		</Drawer >
	)
}


const tabs = [
	{ key: "details", label: "Детали" },
	{ key: "errors", label: "Ошибки" },
	{ key: "dubles", label: "Дубликаты" },
];

const columnsErros = [
	createColumn("Строка", "line_nr_err", "line_nr"),
	createColumn("Значение", "value_err", "value", (text)=>{
		return(
			<div className={styles.textOverflow}>
				{text}
			</div>
		)
	}),
	createColumn("Сообщение", "message_err", "message"),
]

const columnsDubles = [
	createColumn("Строка", "line_nr_dubl", "line_nr"),
	createColumn("Значение", "value_dubl", "value", (text)=>{
		return(
			<div className={styles.textOverflow}>
				{text}
			</div>
		)
	}),
]

function createColumn(title, key, dataIndex, render) {
	return { title, key, dataIndex, render }
}

export default ModalTaskList;
