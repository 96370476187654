import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getAllFilesGraphData, getSandboxGraphLoader } from '../../store/sandbox/selectors';
import { useSelector } from 'react-redux';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import { getDayOfTheWeek } from './utils';
import styles from './Sandbox.module.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const AllFilesGraph = () => {
    const isLoader = useSelector(getSandboxGraphLoader);
    const [currentYear, setCurrentYear] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(null);
    const [currentDate, setCurrentDate] = useState(null);
    const allFilesGraphData = useSelector(getAllFilesGraphData);
    const getHoverColor = (color) => {
        if (color == '#60A5E4') {
            return '#3675AE'
        }
    };
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const getActualFormatAxes = (date) => {
        let actualDate = '';
        actualDate = date.split('-')[2] + '.' + date.split('-')[1];
        return actualDate;
    };

    useEffect(() => {
        const date = new Date();
        setCurrentYear(date.getFullYear());
        setCurrentMonth(date.getMonth() + 1);
        setCurrentDate(date.getDate());
    }, [])


    const tooltipDateFormatter = (date) => {
        let actualDate = '';
        actualDate = date.split('-')[2] + '.' + date.split('-')[1] + '.' + date.split('-')[0];
        return actualDate;
    }

    

    const createExternalTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.backgroundColor = 'white';
            tooltipEl.style.fontSize = '13px'
            tooltipEl.style.fontWeight = '400'
            tooltipEl.style.lineHeight = '18px'
            tooltipEl.style.padding = '8px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            const table = document.createElement('table');
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        const { chart, tooltip } = context;
        const tooltipEl = createExternalTooltip(chart);

        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        const dataIndex = tooltip.dataPoints[0].dataIndex;
        const totalBars = chart.data.labels.length;
        const isLastBars = dataIndex >= totalBars / 2;

        const titledata = tooltipDateFormatter(allFilesGraphData[dataIndex].date);
        const dayOfWeek = getDayOfTheWeek(allFilesGraphData[dataIndex].date)
        const title = `Проверено за ${titledata} (${dayOfWeek})`;

        const bodyLines = tooltip.body.map(b => b.lines);

        const tableHead = document.createElement('thead');
        const trHead = document.createElement('tr');
        const th = document.createElement('th');
        const textHead = document.createTextNode(title);
        th.appendChild(textHead);
        trHead.appendChild(th);
        tableHead.appendChild(trHead);
        th.style.color = '#4B4F55'

        const tableBody = document.createElement('tbody');

        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;

            span.style.borderWidth = '2px';
            span.style.borderRadius = '50%';
            span.style.marginRight = '10px';
            span.style.height = '8px';
            span.style.width = '8px';
            span.style.display = 'inline-block';

            const tr = document.createElement('tr');
            const td = document.createElement('td');

            const text = document.createTextNode(body);
            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
            tr.style.color = '#989AA5'

        });

        const tableRoot = tooltipEl.querySelector('table');
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);

        let tooltipX = chart.canvas.offsetLeft + tooltip.caretX;
        const tooltipY = tooltip.caretY < 270 ? chart.canvas.offsetTop + tooltip.caretY - tooltip.height / 2 : chart.canvas.offsetTop + tooltip.caretY - tooltip.height / 2 - 50;

        if (isLastBars) {
            tooltipX -= tooltip.width + 45;
        }
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${tooltipX}px`;
        tooltipEl.style.top = `${tooltipY}px`;
        tooltipEl.style.font = tooltip.options.bodyFont.string;
    };




    const chartData = {
        labels: allFilesGraphData?.map(item => getActualFormatAxes(item.date)),
        datasets: [
            {
                label: 'Всего',
                data: allFilesGraphData?.map(item => item.requests),
                backgroundColor: '#60A5E4',
                hoverBackgroundColor: '#3675AE',
                barThickness: 20,
                borderRadius: 4,
            },
        ],
    };


    const options = {
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
            onHover: (elements) => {
                if (elements.length) {
                    const index = elements[0].index;
                    setHoveredIndex(index);
                } else {
                    setHoveredIndex(null);
                }
            },
        },
        plugins: {
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
            legend: {
                display: false
            },
            datalabels: {
                display: false,
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: '10px',
                        family: 'Muller'
                    },
                    color: function (value) {
                        const date = value?.tick?.$context?.tick?.label
                        const [day, month] = date.split('.').map(Number)
                        let year = currentYear;
                        if (month > currentMonth || (month === currentMonth && day > currentDate)) {
                            year -= 1;
                        }
                        const formattedDate = new Date(`${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`)
                        const dayOfTheWeek = formattedDate.getDay()
                        return dayOfTheWeek === 0 || dayOfTheWeek === 6 ? 'red' : '#989aa5';
                    }
                }

            },
            y:
            {
                stacked: true,
                border: {
                    display: false,
                }
            },
        },
    };

    const updatedDatasets = chartData.datasets.map((dataset) => {
        return {
            ...dataset,
            backgroundColor: hoveredIndex !== null
                ? dataset.data.map((_, index) => (index === hoveredIndex ? getHoverColor(dataset.backgroundColor) : dataset.backgroundColor))
                : dataset.backgroundColor,
        };
    });

    return (
        <div>
            {isLoader && <PreloaderNew />}
            <div className={styles.stackedBarChartWrapper} style={{ opacity: isLoader ? 0.2 : 1}} >

                <Bar data={{ ...chartData, datasets: updatedDatasets }} options={options} />
            </div>
        </div>

    );
};

export { AllFilesGraph };
