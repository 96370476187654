import React from "react";
import { Button, Modal, Typography } from "antd";
import { IconSuccess } from "../../icons/IconSuccess";
import { IconClose } from "../../icons";
import styles from "./EdlStyles.module.css";

function ModalChangePasswordSuccess({ open, close })
{
	return (
		<Modal
			open={open}
			closable={false}
			footer={null}
			centered
			
		>
			<Button
				onClick={close}
				icon={<IconClose className={styles.addEdlListNewModalCloseIcon} />}
				type="ghost"
				className={styles.closeModalButton}
			/>
			<div className={styles.modalComfirmWrapper}>
				<IconSuccess />

				<Typography.Title level={3}>Пароль успешно <br/>изменен!</Typography.Title>

				<Button
					onClick={close}
					className={styles.changePasswordOkBtn}
					type="primary"
				>
					Ок
				</Button>
			</div>
		</Modal>
	)
}

export default ModalChangePasswordSuccess;