import React from 'react';
import { useSelector } from 'react-redux';
import { getFileTypesGraphData, getSandboxGraphLoader } from '../../store/sandbox/selectors';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import styles from './Sandbox.module.css'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const FileTypesGraph = () => {
    const fileTypesGraphData = useSelector(getFileTypesGraphData);
    const isLoader = useSelector(getSandboxGraphLoader)

    const createExternalTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.style.backgroundColor = 'white';
            tooltipEl.style.fontSize = '13px';
            tooltipEl.style.fontWeight = '400';
            tooltipEl.style.lineHeight = '18px';
            tooltipEl.style.padding = '8px';
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            const table = document.createElement('table');
            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        const { chart, tooltip } = context;
        const tooltipEl = createExternalTooltip(chart);

        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        const dataIndex = tooltip.dataPoints[0].dataIndex;
        const totalBars = chart.data.labels.length;
        const isLastBars = dataIndex >= totalBars / 2;

        const title = 'Проверено за 30 дней';

        const bodyLines = tooltip.body.map((b) => {
            const fileType = chart.data.labels[dataIndex];
            const requests = b.lines[0];
            return `Файлы ${fileType}: ${requests}`;
        });

        const tableHead = document.createElement('thead');
        const trHead = document.createElement('tr');
        const th = document.createElement('th');
        const textHead = document.createTextNode(title);
        th.appendChild(textHead);
        trHead.appendChild(th);
        tableHead.appendChild(trHead);
        th.style.color = '#4B4F55';

        const tableBody = document.createElement('tbody');

        bodyLines.forEach((body, i) => {
            const colors = tooltip.labelColors[i];

            const span = document.createElement('span');
            span.style.background = colors.backgroundColor;
            span.style.borderWidth = '2px';
            span.style.borderRadius = '50%';
            span.style.marginRight = '10px';
            span.style.height = '8px';
            span.style.width = '8px';
            span.style.display = 'inline-block';

            const tr = document.createElement('tr');
            const td = document.createElement('td');

            const text = document.createTextNode(body);
            td.appendChild(span);
            td.appendChild(text);
            tr.appendChild(td);
            tableBody.appendChild(tr);
            tr.style.color = '#989AA5';

        });

        const tableRoot = tooltipEl.querySelector('table');
        while (tableRoot.firstChild) {
            tableRoot.firstChild.remove();
        }

        tableRoot.appendChild(tableHead);
        tableRoot.appendChild(tableBody);

        let tooltipX = chart.canvas.offsetLeft + tooltip.caretX;
        const tooltipY = tooltip.caretY < 270 ? chart.canvas.offsetTop + tooltip.caretY - tooltip.height / 2 : chart.canvas.offsetTop + tooltip.caretY - tooltip.height / 2 - 50;


        if (isLastBars) {
            tooltipX -= tooltip.width + 105;
        }
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = `${tooltipX}px`;
        tooltipEl.style.top = `${tooltipY}px`;
        tooltipEl.style.font = tooltip.options.bodyFont.string;
    };




    const chartData = {
        labels: fileTypesGraphData?.map(item => item.file_type),
        datasets: [
            {
                data: fileTypesGraphData?.map(item => item.requests),
                backgroundColor: ['#EB5757', '#A2B3D4', '#2E496F', '#D160E4', '#327FEF', '#51D83B', '#219653', '#00C7F2', '#FFBC36'],
                barThickness: 48,
                borderRadius: 4,
            },
        ],
    };


    const options = {
        maintainAspectRatio: false,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            tooltip: {
                enabled: false,
                external: externalTooltipHandler,
            },
            legend: {
                display: false
            },
            datalabels: {
                display: false,
            }
        },

        responsive: true,
        scales: {
            x: {
                grid: {
                    display: false
                },
                ticks: {
                    font: {
                        size: '10px',
                        family: 'Muller'
                    }
                }

            },
            y:
            {
                stacked: true,
                border: {
                    display: false,
                }
            },
        },
    };


    return (
        <>
            <div className={styles.stackedBarChartWrapper} style={{opacity:isLoader? 0.2 : 1}} >
                {isLoader && <PreloaderNew/>}
                <Bar data={chartData} options={options} />
            </div>
        </>

    );
};

export { FileTypesGraph };
