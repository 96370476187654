
import React from 'react'
import { Badge, Typography, Button } from "antd";
import { getDocFileSandBox, getMalwareSampleSandBox } from '../../store/sandbox';
import { IconFileDownload } from '../../icons/IconFileDownload';
import { IconCopy } from '../../icons';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import styles from './Sandbox.module.css'

const { Text } = Typography
export const copyBufferTextTable = (text, messageApi) => {
    navigator.clipboard
        .writeText(text)
        .then(() => {
            messageApi.open({
                type: 'info',
                content: 'Скопировано!',
                style: {
                    marginTop: '10px'
                },
                duration: 2
            });
        })
        .catch(() => {
            messageApi.open({
                type: 'error',
                content: 'Ошибка',
                style: {
                    marginTop: '10px',
                },
                duration: 2
            });
        });
};

function createColumn(title, key, width, render) {
    return { title, key, dataIndex: key, width, render };
}
export const getDayOfTheWeek = (date) => {
    const newDate = new Date(date)
    const dayOfWeek = newDate.getDay();
    switch (dayOfWeek) {
        case 0:
            return 'Вс'
        case 1:
            return 'Пн'
        case 2:
            return 'Вт'
        case 3:
            return 'Ср'
        case 4:
            return 'Чт'
        case 5:
            return 'Пт'
        case 6:
            return 'Сб'
    }
}


export const checkTableColumns = (detailsData, dispatch) => {
    return [
        createColumn('Источник, ДАТА (UTC)', 'source', '30%', (text, row) => { return (<Text >{text}<br />{getDateTable(row?.finished_at)}</Text>) }),
        createColumn('Вердикт', 'verdict', '15%', (text, row) => {
            if (text != null) {
                if ((row.source === 'Palo Alto Networks' || row.source === 'CheckPoint') && row.verdict === 'Unsupported') {
                    return (viewVerdictBadge('Unknown'))
                }
                return (viewVerdictBadge(text))
            }
        }),
        createColumn('Детали', 'details', false, (text) => {
            return (text ?
                text?.split("\n")?.map((item, index) => (
                    <div key={index + item}>{item}</div>))
                : " - ")
        }),
        createColumn('Отчет', 'report', '15%', (text, row) => {
            if (text) return (
                <div className={styles.utilsDownloadFileIcon}>
                    <IconFileDownload
                        onClick={() =>
                            dispatch(getDocFileSandBox({ hash: detailsData?.sha256, source: row?.source === 'Palo Alto Networks' ? 'palo-alto' : 'forti' }))} />
                </div>)
        }),
    ]
}





export const getTableDataset = (tableData) => {
    return [
        {
            source: 'Palo Alto Networks',
            verdict: tableData?.verdict_palo_alto,
            finished_at: tableData?.finished_palo_alto_at,
            details: tableData?.details_palo_alto,
            report: tableData?.report_palo_alto

        },
        {
            source: 'Fortinet',
            verdict: tableData?.verdict_fortisandbox,
            finished_at: tableData?.finished_fortisandbox_at,
            details: tableData?.details_fortisandbox,
            report: tableData?.report_fortisandbox

        },
        {
            source: 'Kaspersky',
            verdict: tableData?.verdict_kaspersky,
            finished_at: tableData?.finished_kaspersky_at,
            details: tableData?.details_kaspersky,
            report: tableData?.report_kaspersky
        },
        {
            source: 'CheckPoint',
            verdict: tableData?.verdict_checkpoint,
            finished_at: tableData?.finished_checkpoint_at,
            details: tableData?.details_checkpoint,
            report: null
        },
        {
            source: 'ClamAV',
            verdict: tableData?.verdict_clamav,
            finished_at: tableData?.finished_clamav_at,
            details: tableData?.details_clamav,
            report: null
        }
    ]
}


export const getDateTable = (text) => {
    if (text !== null && text !== ' ' && text !== undefined) {

        const date = text?.split('T')[0]?.split('-')?.reverse()
        const time = text?.split('T')[1]?.split('Z')[0]
        return (
            <div className={styles.utilsDateTimeWrapper}>
                <Text className={styles.utilsDateTime + ' ' + styles.date}>{`${date[0]}.${date[1]}.${date[2]}`}</Text>
                <Text className={styles.utilsDateTime + ' ' + styles.time}>{time.split('.')[0]}</Text>
            </div>
        )
    }
}

export const changeKeyToTitle = (key) => {
    switch (key) {
        case 'sha256':
            return 'sha 256';
        case 'verdict':
            return 'Verdict';
        case 'first_seen':
            return 'First seen (UTC)';
        case 'last_seen':
            return 'Last seen (UTC)';
        case 'hits':
            return 'Hits';
        case 'file_size':
            return 'File size';
        case 'file_type':
            return 'File type';
        case 'file_name':
            return 'File name';
        case 'file_sample':
            return 'Download File Sample'
        case 'file_url':
            return 'Source URL';
        case 'source_ip':
            return 'Source IP';
        default:
            break;
    }

}

export function parseIfUserName(org, orgId_userId) {
    const regex = /\d+_+\d+/
    let userName = "Не найдено"
    if (regex.test(orgId_userId)) {
        org?.users?.forEach((user) => {
            if (user?.id?.toString() === orgId_userId.split('_')[1]) {
                userName = '';
                if (user?.first_name) {
                    userName = user?.first_name + ' '
                }
                if (user?.last_name) {
                    userName = userName + user?.last_name
                }
            }
        })
        return userName
    }
    else if (orgId_userId?.length === 64) {
        return 'API'
    }
    else return orgId_userId;

}




const viewVerdictBadge = (verdict, parus) => {
    switch (verdict) {
        case "Malware":
            return (
                <Badge
                    className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                    count={verdict?.toUpperCase()} showZero color='#FFECEC' />
            )
        case "Clean":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.clearOrBenign}
                count={parus === 'parus' ? 'benign'.toUpperCase() : verdict?.toUpperCase()} showZero color='#E0F6EA' />
            )
        case "Grayware":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.graywareOrMediumRisk}
                count={verdict?.toUpperCase()} showZero color='#FFF9EC' />
            )
        case "Not applicable":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.notApplicable}
                count={verdict?.toUpperCase()} showZero color='#ff7a45' />
            )
        case "Adware and other":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.adwareAndOther}
                count={verdict?.toUpperCase()} showZero color='#ffda99' />
            )
        case "Not categorized":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.notCategorizedOrUnknown}
                count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
            )
        case null:
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.notCategorizedOrUnknown}
                count={parus === 'parus' ? 'unknown'.toUpperCase() : ''} showZero color='#f8f4ff' />
            )
        case "Unknown":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.notCategorizedOrUnknown}
                count={verdict?.toUpperCase()} showZero color='#f8f8ff' />
            )
        case "NotAVirus":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.notAVirusOrUnsupportedOrLowRisk}
                count={verdict?.toUpperCase()} showZero color='#ffda99' />
            )
        case "Benign":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.clearOrBenign}
                count={verdict?.toUpperCase()} showZero color='#E0F6EA' />
            )
        case "Unsupported":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.notAVirusOrUnsupportedOrLowRisk}
                count={verdict?.toUpperCase()} showZero color='#ffda99' />
            )
        case "Error":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.error}
                count={verdict?.toUpperCase()} showZero color='#9e1068' />
            )
        case "Low Risk":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.notAVirusOrUnsupportedOrLowRisk}
                count={verdict?.toUpperCase()} showZero color='#ffda99' />
            )
        case "Coming soon":
            return (
                <Badge 
                className={styles.utilsBadge + ' ' + styles.comingSoon}
                count={verdict?.toUpperCase()} showZero color='white' />
            )
        case "Medium Risk":
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.graywareOrMediumRisk}
                count={verdict?.toUpperCase()} showZero color='#FFF9EC' />
            )
        case "High Risk":
            return (
                <Badge
                    className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                    count={verdict?.toUpperCase()} showZero color='#FFECEC' />
            )
        case "Critical Risk":
            return (
                <Badge
                    className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                    count={verdict?.toUpperCase()} showZero color='#FFECEC' />
            )
        default:
            return (
                <Badge
                className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                count={verdict?.toUpperCase()} showZero color='#FFECEC' />
            )
    }
};

function createUrlIpBadge(categories) {
    return categories?.map((category) => {

        if (category?.severity === 2) {
            return (
                <Badge key={category?.cat_id} 
                className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                count={category.name?.toUpperCase()} showZero color='#FFECEC' />
            )
        }
        else if (category?.severity === 0) {
            return (
                <Badge key={category?.cat_id} 
                className={styles.utilsBadge + ' ' + styles.clearOrBenign}
                count={category?.name?.toUpperCase()} showZero color='#E0F6EA' />
            )
        }
        else if (category?.severity === 1) {
            return (
                <Badge key={category?.cat_id} 
                className={styles.utilsBadge + ' ' + styles.graywareOrMediumRisk}
                count={category?.name?.toUpperCase()} showZero color='#FFF9EC' />
            )
        }

        return null;


    })
}

export const parseValue = (key, value, urlLoader, urlData, ipLoader, categoryErrors, dispatch, messageApi, requestUid, detailsData) => {
    if ((key === 'first_seen' || key === 'last_seen') && value) {
        const date = (value.split('T')[0]).split('-').reverse()
        const time = (value.split('T')[1]).split('Z')[0]
        return (
            <div>
                <Text className={styles.darkGreyColor}>{`${date[0]}.${date[1]}.${date[2]} `}</Text>
                <Text className={styles.lightGreyColor}>{time.split('.')[0]}</Text>
            </div>
        )
    }
    else if (key === 'sha256') {
        return (
            <div className={styles.utilsShaWrapper}>
                <div className={styles.utilsShaAndFilenameText}>{`${value} `}</div>
                <Button
                    size="small"
                    type="text"
                    icon={<IconCopy className={styles.copyIcon} />}
                    onClick={() => {
                        copyBufferTextTable(value, messageApi);
                    }}
                />
            </div>
        )
    }
    else if (key === 'verdict') {
        const href = `https://www.virustotal.com/gui/file/${detailsData?.sha256}`
        return (
            <div className={styles.utilsVerdictWrapper}>
                {viewVerdictBadge(value, 'parus')}
                {detailsData?.verdict === 'Malware' && <a href={href} target='_blank' className={styles.utilsLink}>открыть VirusTotal</a>}
            </div>

        )
    }
    else if (key === 'file_name') {
        return (
            <div className={styles.utilsShaAndFilenameText}>
                <Text className={styles.darkGreyColor}>{`${value || '-'} `}</Text>
            </div>
        )
    }
    else if (key === 'file_sample' && value) {
        return (
            <div className={styles.utilsFileSampleDownloadIcon}>
                <IconFileDownload onClick={() => { dispatch(getMalwareSampleSandBox(requestUid)) }} />
            </div>
        )
    }
    else if (key === 'file_url') {
        return (

            <div className={styles.utilsIpAndUrlWrapper}>
                {urlLoader && <PreloaderNew />}
                <div 
                className={styles.utilsIpAndUrl}
                style={{
                    opacity: urlLoader ? 0.2 : 1, 
                    minHeight: urlLoader ? 80 : 0
                }}>
                    {value && value !== 'unknown' && <Text className={styles.darkGreyColor}>{`${value || ''} `}</Text>}
                    {value && value !== 'unknown' && <Button
                        size="small"
                        type="text"
                        icon={<IconCopy style={{ width: 16, height: 16, color: "#8090B8" }} />}
                        onClick={() => {
                            copyBufferTextTable(value, messageApi);
                        }}
                    />}
                </div>
                {categoryErrors && value && value !== 'unknown' && <Text className={styles.redColor}>{categoryErrors}</Text>}
                {urlData && <div className={styles.utilsUrlBadgesArea}>
                    {createUrlIpBadge(urlData?.categories)}
                    {urlData?.kfeed_results?.length > 0 &&
                        <Badge key='kfeed-url' 
                        className={styles.utilsBadge + ' ' + styles.malwareOrHighRiskOrCriticalRiskOrDefault}
                        count={'kfeed'.toUpperCase()} showZero color='#FFECEC' />}
                </div>}
            </div>
        )
    }
    else if (key === 'source_ip') {
        return (
            <div className={styles.utilsIpAndUrlWrapper}>
                {ipLoader && <PreloaderNew />}
                <div 
                className={styles.utilsIpAndUrl}
                style={{
                    opacity: ipLoader ? 0.2 : 1, 
                    minHeight: ipLoader ? 80 : 0
                }}>
                    <Text className={styles.darkGreyColor}>{`${value || ''} `}</Text>
                    {value && value !== 'unknown' && <Button
                        size="small"
                        type="text"
                        icon={value !== 'unknown' ? <IconCopy className={styles.copyIcon} /> : null}
                        onClick={() => {
                            copyBufferTextTable(value, messageApi);
                        }}
                    />}
                </div>
                {categoryErrors && value && value !== 'unknown' && <Text className={styles.redColor}>{categoryErrors}</Text>}
            </div>
        )
    }
    else if (key === 'file_size') {
        return (
            <Text className={styles.darkGreyColor}>{`${value || 0} байт`}</Text>
        )
    }
    else {
        return (
            <Text className={styles.darkGreyColor}>{value}</Text>
        )
    }
}