import React from "react";
import styles from "./PasswordRecoverNew.module.css"
import background_image from "../../../images/loginBackground.jpg";
import { useSelector, useDispatch } from "react-redux";
import { resetPassword } from "../../../store/auth";
import { getChangePassLoader, getError, getIsSuccessChangePassword } from "../../../store/auth/selectors";
import { Form, Input, Button, Spin, Alert, Typography } from "antd";
import { getUrlLoaders } from "../../../store/urlfilter/selectors";
import PreloaderNew from "../PreloaderNew/PreloaderNew";

const { Title } = Typography;

function PasswordRecoverNew() {
    const dispatch = useDispatch();
    const errors = useSelector(getError);
    const isSuccess = useSelector(getIsSuccessChangePassword);
    const isLoading = useSelector(getChangePassLoader);
    const isLoader = useSelector(getUrlLoaders);

    return (
        <Spin size="large" spinning={isLoader}>
            <div className={styles.passwordRecoverWrapper}>

                <img
                    src={background_image}
                    className={styles.backgroundImage}
                    alt='background'
                />
                <div
                    className={styles.passwordRecoverFormWrapper}
                >
                    <Title level={2} className={styles.passwordRecoverFormTitle}>Восстановление пароля</Title>
                    {/* {errors && <Alert type="error" message={JSON.stringify(errors, null, 4)} style={{ marginBottom: 16, maxWidth: 350 }} />} */}
                    {errors && <Alert
                        message="Authorization Error"
                        description={errors}
                        type="error"
                        showIcon
                    />}
                    {isSuccess && <Alert
                        message={isSuccess}

                        type="success"
                        showIcon
                    />}
                    <Form
                        size="large"
                        name="auth"
                        layout="vertical"
                        requiredMark={false}
                        className={styles.form}
                        onFinish={({ email }) => {

                            dispatch(resetPassword({ email: email }));
                        }}
                    >
                        {isLoading && <PreloaderNew />}
                        <Form.Item
                            label="E-mail"
                            name="email"
                            rules={[{ required: true, message: "Пожалуйста, введите Ваш email!" }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item>
                            <div className={styles.passwordRecoverGoToLoginWrapper}>

                                <div className={styles.passwordRecoverGoToLoginDivider} />
                                <Button type="link" size="small" className={styles.passwordRecoverGoToLogin} href='/login'>
                                    Вход на портал
                                </Button>
                            </div>
                        </Form.Item>

                        <Form.Item className={styles.passwordRecoverButtonWrapper}>
                            <Button
                                disabled={isSuccess || isLoading ? true : false}
                                type="primary"
                                htmlType="submit"
                                className={styles.passwordRecoverButton}
                            >
                                Отправить
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </Spin>

    )
}

export default PasswordRecoverNew;