import React from "react";
import { Button,  Modal, } from "antd";
import { IconClose } from "../../icons";
import PatchEdlLInstanceNew from "./PatchEdlLInstanceNew ";
import styles from "./EdlStyles.module.css";


function ModaLPatchInctance({ open, close, item, list }) {
	return (
		<Modal
		open={open}
		closable={false}
		footer={null}
		centered

	>
		<Button
			onClick={close}
			icon={<IconClose className={styles.addEdlListNewModalCloseIcon} />}
			type="ghost"
			className={styles.closeModalButton}
		/>
		<PatchEdlLInstanceNew close={close} item={item} list={list}/>
		</Modal>
	)
}



export default ModaLPatchInctance;
