import React from 'react'
import { Typography, Row, Spin } from 'antd';
import styles from './Sandbox.module.css'
import { getSandboxOverallLoader } from '../../store/sandbox/selectors';
import { useSelector } from 'react-redux';
import PreloaderNew from '../UI/PreloaderNew/PreloaderNew';
import { DownOutlined, LoadingOutlined, SyncOutlined} from '@ant-design/icons';
import { numberWithSpaces } from '../../utils/numberWithSpaces';
const { Text } = Typography;


const listItemStyle = { display: 'flex', justifyContent: 'space-between' }
export default function MetricsArea({ dataStats, fetchStatsData }) {
  const styleTextGray = { color: "#7A7D86", fontSize: 16 };

  const isLoading = useSelector(getSandboxOverallLoader);
  return (
    <div data-testid='cards-area' style={{opacity: isLoading.today? 0.2 : 1}} className={styles.limitsCards}>
      {isLoading?.today && <PreloaderNew/>}
      <div>
        <Text>Проверено новых файлов</Text>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{numberWithSpaces(dataStats?.checked_files_today)}</Text>
        </Row>
        <Row style={listItemStyle}>
          <div style={{display: 'flex',  cursor: 'pointer'}} onClick={()=> fetchStatsData('checked_files_total')}><Text style={styleTextGray}>Всего</Text>{dataStats?.checked_files_total!==null
          ? <SyncOutlined style={{color: 'rgb(50, 127, 239)', fontSize: 12, marginLeft: 6}}/> 
          :   <DownOutlined className={styles.iconDown}/>}</div>
         {isLoading?.checked_files_total? <Spin indicator={<LoadingOutlined spin />} size="small" /> :  <Text >{dataStats?.checked_files_total!==null && numberWithSpaces(dataStats?.checked_files_total)}</Text>}
        </Row>
      </div>
      <div>
        <Text>Проверено архивов</Text>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{numberWithSpaces(dataStats?.checked_archives_today)}</Text>
        </Row>
        <Row style={listItemStyle}>
        <div style={{display: 'flex',  cursor: 'pointer'}} onClick={()=> fetchStatsData('checked_archives_total')}> <Text style={styleTextGray}>Всего</Text>{dataStats?.checked_archives_total!==null
        ? <SyncOutlined style={{color: 'rgb(50, 127, 239)', fontSize: 12, marginLeft: 6}}/>  
          :   <DownOutlined className={styles.iconDown}/>}</div>
         {isLoading?.checked_archives_total ? <Spin indicator={<LoadingOutlined spin />} size="small" /> :  <Text >{dataStats?.checked_archives_total!==null && numberWithSpaces(dataStats?.checked_archives_total)}</Text>}
        </Row>
      </div>
      <div>
        <div
          style={{
            backgroundColor: "#E0F6EA",

            color: "#219653",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          BENIGN
        </div>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{numberWithSpaces(dataStats?.benign_requests_today)}</Text>
        </Row>
        <Row style={listItemStyle}>
        <div style={{display: 'flex',  cursor: 'pointer'}} onClick={()=> fetchStatsData('benign_requests_total')}><Text style={styleTextGray}>Всего</Text>{dataStats?.benign_requests_total!==null
        ? <SyncOutlined style={{color: 'rgb(50, 127, 239)', fontSize: 12, marginLeft: 6}}/>  
          :   <DownOutlined className={styles.iconDown}/>}</div>
         {isLoading?.benign_requests_total ? <Spin indicator={<LoadingOutlined spin />} size="small" /> :  <Text >{dataStats?.benign_requests_total!==null && numberWithSpaces(dataStats?.benign_requests_total)}</Text>}
        </Row>

      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FFF9EC",

            color: "#E39800",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          GRAYWARE
        </div>

        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{numberWithSpaces(dataStats?.grayware_requests_today)}</Text>
        </Row>
        <Row style={listItemStyle}>
        <div style={{display: 'flex',  cursor: 'pointer'}} onClick={()=> fetchStatsData('grayware_requests_total')}><Text style={styleTextGray}>Всего</Text>{dataStats?.grayware_requests_total!==null
        ? <SyncOutlined style={{color: 'rgb(50, 127, 239)', fontSize: 12, marginLeft: 6}}/> 
          :   <DownOutlined className={styles.iconDown}/>}</div>
          {isLoading?.grayware_requests_total ? <Spin indicator={<LoadingOutlined spin />} size="small" /> :  <Text >{dataStats?.grayware_requests_total!==null && numberWithSpaces(dataStats?.grayware_requests_total)}</Text>}
        </Row>

      </div>
      <div>
        <div
          style={{
            backgroundColor: "#FFECEC",
            color: "#EB5757",

            display: "inline",
            borderRadius: 2,
            padding: "0",
            paddingLeft: 0,
            textTransform: "uppercase",
          }}
        >
          MALWARE
        </div>
        <Row style={listItemStyle}>
          <Text style={styleTextGray}>Сегодня</Text>
          <Text >{numberWithSpaces(dataStats?.malware_requests_today)}</Text>
        </Row>
        <Row style={listItemStyle}>
        <div style={{display: 'flex', cursor: 'pointer', alignItems: 'baseline'}} onClick={()=> fetchStatsData('malware_requests_total')}><Text style={styleTextGray}>Всего</Text>{dataStats?.malware_requests_total!==null
        ? <SyncOutlined style={{color: 'rgb(50, 127, 239)', fontSize: 12, marginLeft: 6}}/> 
          :   <DownOutlined className={styles.iconDown}/>}</div>
          {isLoading?.malware_requests_total ? <Spin indicator={<LoadingOutlined spin />} size="small" /> :  <Text >{dataStats?.malware_requests_total!==null && numberWithSpaces(dataStats?.malware_requests_total)}</Text>}
        </Row>
      </div>
    </div>

  )
}