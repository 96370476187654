import React from "react";
import { Drawer } from "antd";
import AddEdlListNew from "./AddEdlListNew";

function ModalAddList({ open, close }) {
  return (
    <Drawer
      open={open}
      onClose={close}
      maskClosable={false}
      width={704}
      styles={{ header: { display: "none" } }}
    >

      <AddEdlListNew close={close} />
    </Drawer>
  );
}

export default ModalAddList;
